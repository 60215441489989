import React from "react";
// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
// OTHER
import fulfillment from "./Risk.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import NumberField from "../../../components/NumberField/NumberField";
import Select from "../../../../../components/Select/Select";
import TextField from "../../../../../components/TextField/TextField";
import Modal from "../../../../../components/Modal/Modal";
import SimpleTextField from "../../../components/SimpleTextField/SimpleTextField";
import TextView from "../../../../../components/TextView/TextView";


export default class RiskView extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}
						this.setState({hasFormValidationErrors: true});
						return setAppState({showValidationWarning: true});
					}
				}
			}
		});
	}

	render()
	{
		const {state} = this;
		const {customerState} = state;
		const hasFormValidationErrors = !this.state.isFulfilled && this.state.hasFormValidationErrors;
		const {customer} = this.props;
		const classes = createClassName("Cob-view Cob-view6", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<Modal visible={customerState.radio_advice_customer_riskrev === "no"} status="danger" title="Varning">
					<React.Fragment>
						<p>
							Kunden måste göras införstådd med att ökade krav på avkastning även leder till ökad risk.
						</p>

						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({
											  radio_advice_customer_riskrev: customerState.radio_advice_customer_riskrev === "no" ? "" : this.state.customerState.radio_advice_customer_riskrev
										  })}/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>Lämplighetsbedömning</h2>

					<div className="Cob-view6-fields">
						<div className="question" style={{width: "100%", display: "block"}}>
							<Checkbox name="checkbox_advice_skip_placement"
									  label="Hoppa över lämplighetsbedömning - ingen placering vid detta rådgivningstillfälle"
									  value="yes"
									  checked={customerState.checkbox_advice_skip_placement === "yes"}
									  onChange={checked => this.setCustomerState({checkbox_advice_skip_placement: checked ? "yes" : "no"})}
							/>

							{customerState.checkbox_advice_skip_placement !== "yes" && (
								<React.Fragment>
									<div className="h-line spacing"/>

									<h3 style={{paddingBottom: "10px"}}>Risk på kundens portfölj</h3>

									<Checkbox
										type="checkbox"
										name="checkbox_advice_riskinfo"
										label='Kunden har tagit del av och förstått bilagan "Information om riskklasser"'
										value={customerState.checkbox_advice_riskinfo === "Ja" ? "Ja" : "Nej"}
										checked={customerState.checkbox_advice_riskinfo === "Ja"}
										onChange={checked => this.setCustomerState({checkbox_advice_riskinfo: checked ? "Ja" : "Nej"})}
										style={{paddingRight: "15px"}}
										highlightErrors={hasFormValidationErrors}
									/>

									<h4 style={{padding: "30px 0 0 0", margin: "0 0 10px 0"}}>
										Önskad risknivå, totala portföljen
									</h4>
									<div className="numbers-with-colors">
										<OptionsList
											type="radio"
											name="radio_advice_portfolio_risk"
											value={customerState.radio_advice_portfolio_risk}
											options={[
												{
													label: "1",
													value: "1",
													checked: customerState.radio_advice_portfolio_risk === "1"
												},
												{
													label: "2",
													value: "2",
													checked: customerState.radio_advice_portfolio_risk === "2"
												},
												{
													label: "3",
													value: "3",
													checked: customerState.radio_advice_portfolio_risk === "3"
												},
												{
													label: "4",
													value: "4",
													checked: customerState.radio_advice_portfolio_risk === "4"
												},
												{
													label: "5",
													value: "5",
													checked: customerState.radio_advice_portfolio_risk === "5"
												},
												{
													label: "6",
													value: "6",
													checked: customerState.radio_advice_portfolio_risk === "6"
												},
												{
													label: "7",
													value: "7",
													checked: customerState.radio_advice_portfolio_risk === "7"
												},
											]}
											onChange={(checked, radio_advice_portfolio_risk) => this.setCustomerState({radio_advice_portfolio_risk})}
											validation={this._optionsValidation}
											style={{maxWidth: "unset"}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>

									<h4 style={{padding: "30px 0 0 0", margin: "0 0 10px 0"}}>
										Vilken risknivå stämmer bäst överrens med kundens riskbenägenhet vid just det
										här investeringstillfället?
									</h4>
									<div className="numbers-with-colors">
										<OptionsList
											type="radio"
											name="radio_advice_riskpropensity"
											value={customerState.radio_advice_riskpropensity}
											options={[
												{
													label: "1",
													value: "1",
													checked: customerState.radio_advice_riskpropensity === "1"
												},
												{
													label: "2",
													value: "2",
													checked: customerState.radio_advice_riskpropensity === "2"
												},
												{
													label: "3",
													value: "3",
													checked: customerState.radio_advice_riskpropensity === "3"
												},
												{
													label: "4",
													value: "4",
													checked: customerState.radio_advice_riskpropensity === "4"
												},
												{
													label: "5",
													value: "5",
													checked: customerState.radio_advice_riskpropensity === "5"
												},
												{
													label: "6",
													value: "6",
													checked: customerState.radio_advice_riskpropensity === "6"
												},
												{
													label: "7",
													value: "7",
													checked: customerState.radio_advice_riskpropensity === "7"
												},
											]}
											onChange={(checked, radio_advice_riskpropensity) => this.setCustomerState({radio_advice_riskpropensity})}
											validation={this._optionsValidation}
											style={{maxWidth: "unset"}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>

									{customerState.radio_advice_riskpropensity && customerState.radio_advice_portfolio_risk &&
										customerState.radio_advice_riskpropensity !== customerState.radio_advice_portfolio_risk && (
											<div style={{
												background: "#F7F7F7",
												padding: "20px",
												borderRadius: "6px",
												margin: "30px 0 0 0",
												alignItems: "flex-start",
												maxWidth: "600px"
											}}>
												<strong style={{
													lineHeight: "18px",
													paddingBottom: "20px"
												}}>
													Om risknivån vid nuvarande investeringstillfälle inte stämmer
													överrens
													med kundens önskade totala portföljrisk, motivera varför.
												</strong>
												<Checkbox
													type="checkbox"
													name="checkbox_advice_invrisk_same"
													label="Investeringen vid detta tillfälle utgör en så liten del av kundens samlade portfölj att den totala risknivån påverkas minimalt"
													value={customerState.checkbox_advice_invrisk_same === "Ja" ? "Ja" : "Nej"}
													checked={customerState.checkbox_advice_invrisk_same === "Ja"}
													onChange={checked => this.setCustomerState({checkbox_advice_invrisk_same: checked ? "Ja" : "Nej"})}
													highlightErrors={hasFormValidationErrors}
													groupValid={
														customerState.checkbox_advice_invrisk_same === "Ja" ||
														customerState.checkbox_advice_invrisk_changed === "Ja" ||
														customerState.checkbox_advice_invrisk_other === "Ja"
													}
												/>

												<Checkbox
													type="checkbox"
													name="checkbox_advice_invrisk_changed"
													label="Kunden önskar en annan riskprofil med den här investeringen än den totala portföljrisken"
													value={customerState.checkbox_advice_invrisk_changed === "Ja" ? "Ja" : "Nej"}
													checked={customerState.checkbox_advice_invrisk_changed === "Ja"}
													onChange={checked => this.setCustomerState({checkbox_advice_invrisk_changed: checked ? "Ja" : "Nej"})}
													style={{marginTop: "20px"}}
													highlightErrors={hasFormValidationErrors}
													groupValid={
														customerState.checkbox_advice_invrisk_same === "Ja" ||
														customerState.checkbox_advice_invrisk_changed === "Ja" ||
														customerState.checkbox_advice_invrisk_other === "Ja"
													}
												/>

												<Checkbox
													type="checkbox"
													name="checkbox_advice_invrisk_other"
													label="Annat (Berätta vad)"
													value={customerState.checkbox_advice_invrisk_other === "Ja" ? "Ja" : "Nej"}
													checked={customerState.checkbox_advice_invrisk_other === "Ja"}
													onChange={checked => this.setCustomerState({checkbox_advice_invrisk_other: checked ? "Ja" : "Nej"})}
													style={{marginTop: "20px"}}
													highlightErrors={hasFormValidationErrors}
													groupValid={
														customerState.checkbox_advice_invrisk_same === "Ja" ||
														customerState.checkbox_advice_invrisk_changed === "Ja" ||
														customerState.checkbox_advice_invrisk_other === "Ja"
													}
												/>

												{customerState.checkbox_advice_invrisk_other === "Ja" && (
													<TextField
														style={{marginTop: "10px"}}
														name="advice_invrisk_other_expl"
														type="text"
														label="Ange"
														validation={value => value.length > 0}
														value={customerState.advice_invrisk_other_expl}
														onChange={({target}) => this.setCustomerState({advice_invrisk_other_expl: target.value})}
														highlightErrors={hasFormValidationErrors}
													/>
												)}
											</div>
										)}

									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth
												 style={{marginTop: "40px"}}>
										<div style={{alignSelf: "flex-end"}}>
											<strong style={{display: "block", padding: "10px 0"}}>
												Placeringshorisont, detta investeringstillfälle
											</strong>
											<Select
												name="radio_advice_savehoriz_newinv"
												label="Ange år"
												selectedIndex={customerState.radio_advice_savehoriz_newinvIndex}
												options={[
													{label: "<1 år", value: "<1"},
													{label: "1-2 år", value: "1-2"},
													{label: "2-4 år", value: "2-4"},
													{label: "4-6 år", value: "4-6"},
													{label: "6-10 år", value: "6-10"},
													{label: ">10 år", value: ">10"},
												]}
												onChange={(checked, radio_advice_savehoriz_newinv) => this.setCustomerState({radio_advice_savehoriz_newinv})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>

										<div style={{alignSelf: "flex-end"}}>
											<strong
												style={{display: "block", padding: "0 0 10px 0", lineHeight: "20px"}}>
												Andel av placeringsbara tillgångar som placeras vid detta
												investeringstillfälle
											</strong>
											<Select
												name="checkbox_advice_invpercentage"
												label="Ange i procent"
												selectedIndex={customerState.checkbox_advice_invpercentageIndex}
												options={[
													{label: "0-5%", value: "0-5"},
													{label: "6-10%", value: "6-10"},
													{label: "11-20%", value: "11-20"},
													{label: ">20%", value: ">20"}
												]}
												onChange={(checked, checkbox_advice_invpercentage) => this.setCustomerState({checkbox_advice_invpercentage})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>

										<div style={{alignSelf: "flex-end"}}>
											<strong
												style={{display: "block", padding: "0 0 10px 0", lineHeight: "20px"}}>
												Vilket avkastningsmål har kunden vid det här investeringstillfället
											</strong>
											<NumberField
												name="advice_profitgoal"
												label="På årlig basis, ange i procent"
												unit="%"
												value={customerState.advice_profitgoal || ""}
												validation={value => (!isNaN(value) && value.length > 0 && value >= 0 && value <= 100)}
												showSeparators={false}
												onChange={({target}) =>
												{
													this.setCustomerState({advice_profitgoal: target.value.split(' ').join('')});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>


									{customer.isCompany && (
										<React.Fragment>
											<OptionsList
												type="checkbox"
												description="Varifrån kommer de pengar du investerar i detta investeringstillfälle?"
												orientation="vertical"
												value={
													customerState.checkbox_aml_origin_savings === "Ja" ||
													customerState.checkbox_aml_origin_salary === "Ja" ||
													customerState.checkbox_aml_origin_re === "Ja" ||
													customerState.checkbox_aml_origin_savings_heritage === "Ja" ||
													customerState.checkbox_aml_origin_pension === "Ja" ||
													customerState.checkbox_aml_origin_capital_return === "Ja" ||
													customerState.checkbox_aml_origin_owncomp === "Ja" ||
													customerState.checkbox_aml_origin_companyexit === "Ja" ||
													customerState.checkbox_aml_origin_other === "Ja" ?
														"filled" :
														""
												}
												options={[
													{
														name: "checkbox_aml_origin_savings",
														label: "Sparande",
														value: "Ja",
														checked: customerState.checkbox_aml_origin_savings === "Ja",
														onChange: checked => this.setCustomerState({checkbox_aml_origin_savings: checked ? "Ja" : "no"})
													},
													{
														name: "checkbox_aml_origin_salary",
														label: "Lön",
														value: "Ja",
														checked: customerState.checkbox_aml_origin_salary === "Ja",
														onChange: checked => this.setCustomerState({checkbox_aml_origin_salary: checked ? "Ja" : "no"})
													},
													{
														name: "checkbox_aml_origin_re",
														label: "Fastighetsförsäljning",
														value: "Ja",
														checked: customerState.checkbox_aml_origin_re === "Ja",
														onChange: checked => this.setCustomerState({checkbox_aml_origin_re: checked ? "Ja" : "no"})
													},
													{
														name: "checkbox_aml_origin_savings_heritage",
														label: "Arv",
														value: "Ja",
														checked: customerState.checkbox_aml_origin_savings_heritage === "Ja",
														onChange: checked => this.setCustomerState({checkbox_aml_origin_savings_heritage: checked ? "Ja" : "no"})
													},
													{
														name: "checkbox_aml_origin_pension",
														label: "Pension",
														value: "Ja",
														checked: customerState.checkbox_aml_origin_pension === "Ja",
														onChange: checked => this.setCustomerState({checkbox_aml_origin_pension: checked ? "Ja" : "no"})
													},
													{
														name: "checkbox_aml_origin_capital_return",
														label: "Investeringar",
														value: "Ja",
														checked: customerState.checkbox_aml_origin_capital_return === "Ja",
														onChange: checked => this.setCustomerState({checkbox_aml_origin_capital_return: checked ? "Ja" : "no"})
													},
													{
														name: "checkbox_aml_origin_owncomp",
														label: "Intäkter från företaget jag representerar",
														value: "Ja",
														checked: customerState.checkbox_aml_origin_owncomp === "Ja",
														onChange: checked => this.setCustomerState({checkbox_aml_origin_owncomp: checked ? "Ja" : "no"})
													},
													{
														name: "checkbox_aml_origin_companyexit",
														label: "Företagsförsäljning",
														value: "Ja",
														checked: customerState.checkbox_aml_origin_companyexit === "Ja",
														onChange: checked => this.setCustomerState({checkbox_aml_origin_companyexit: checked ? "Ja" : "no"})
													},
													{
														name: "checkbox_aml_origin_other",
														label: "Annat (beskriv nedan)",
														value: "Ja",
														checked: customerState.checkbox_aml_origin_other === "Ja",
														onChange: checked => this.setCustomerState({checkbox_aml_origin_other: checked ? "Ja" : "no"})
													}
												]}
												validation={value => (value && value === "filled") || false}
												highlightErrors={hasFormValidationErrors}
											/>
										</React.Fragment>
									)}

									{customerState.checkbox_aml_origin_other === "Ja" && (
										<TextField
											name="aml_origin_other_desc"
											label="Vänligen beskriv"
											value={customerState.aml_origin_other_desc}
											validation={this._textValidation}
											style={{marginTop: "10px"}}
											onChange={({target}) => this.setCustomerState({aml_origin_other_desc: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									)}

									<strong style={{display: "block", padding: "10px 0"}}>
										Är kunden införstådd med att ett högre avkastningsmål också innebär ett
										högre risktagande
									</strong>
									<div style={{alignItems: "flex-start", maxWidth: "240px"}}>
										<OptionsList
											type="radio"
											name="radio_advice_customer_riskrev"
											style={{maxWidth: "none"}}
											value={customerState.radio_advice_customer_riskrev}
											options={[
												{
													label: "Nej",
													value: "no",
													checked: customerState.radio_advice_customer_riskrev === "no"
												},
												{
													label: "Ja",
													value: "Ja",
													checked: customerState.radio_advice_customer_riskrev === "Ja"
												}
											]}
											onChange={(checked, radio_advice_customer_riskrev) => this.setCustomerState({radio_advice_customer_riskrev})}
											validation={this._optionsValidation}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>

									<div className="h-line spacing" style={{marginTop: "30px"}}/>

									<div style={{width: "100%", display: "block", marginTop: "0"}}>
										<h4>Har kunden några hållbarhetspreferenser?</h4>
										<p style={{marginBottom: "0"}}>Enligt bilagan <em>Information om hållbarhetspreferenser</em></p>

										<div style={{alignItems: "flex-start", maxWidth: "240px", width: "100%"}}>
											<OptionsList
												type="radio"
												name="radio_hallbarhet_has_preferences"
												style={{maxWidth: "none"}}
												value={customerState.radio_hallbarhet_has_preferences}
												options={[
													{
														label: "Nej",
														value: "no",
														checked: customerState.radio_hallbarhet_has_preferences === "no"
													},
													{
														label: "Ja",
														value: "yes",
														checked: customerState.radio_hallbarhet_has_preferences === "yes"
													}
												]}
												onChange={(checked, radio_hallbarhet_has_preferences) => this.setCustomerState({radio_hallbarhet_has_preferences})}
												validation={this._optionsValidation}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>

										{customerState.radio_hallbarhet_has_preferences === "yes" && (
											<React.Fragment>
												<p className={"Cob-OptionsList-description"} style={{marginTop: "20px"}}>
													Vilken/vilka av följande instrumenttyper avser dina
													hållbarhetspreferenser?
												</p>

												<Checkbox style={{maxWidth: "740px", width: "100%", marginTop: "20px"}}
														  name="checkbox_hallbarhet_852"
														  label="Ett finansiellt instrument i fråga om vilket kunden bestämmer att en viss minimiandel ska investeras i miljömässigt hållbara investeringar enligt definitionen i artikel 2.1 i Europaparlamentets och rådets förordning (EU) 2020/852."
														  value="yes"
														  checked={customerState.checkbox_hallbarhet_852 === "yes"}
														  onChange={checked => this.setCustomerState({checkbox_hallbarhet_852: checked ? "yes" : "no"})}
														  highlightErrors={hasFormValidationErrors}
														  groupValid={
															  customerState.checkbox_hallbarhet_852 === "yes" ||
															  customerState.checkbox_hallbarhet_2088 === "yes" ||
															  customerState.checkbox_hallbarhet_customer === "yes" ||
															  customerState.checkbox_hallbarhet_art6 === "yes"
														  }
												/>

												{customerState.checkbox_hallbarhet_852 === "yes" && (
													<React.Fragment>
														<div style={{
															paddingLeft: "35px",
															paddingBottom: "20px",
															display: "block"
														}}>
															<p style={{
																marginBottom: "5px",
																fontSize: "13px",
																fontWeight: "bold"
															}}>
																Minimiandel
															</p>
															<div style={{flexDirection: "row"}}>
																<SimpleTextField
																	type="number"
																	name={"customer_hallbarhet_852_minimum"}
																	placeholder="Ange i %"
																	value={customerState.customer_hallbarhet_852_minimum || ""}
																	textAlign="left"
																	validation={value => (value.length > 0 && value > 0 && value <= 100)}
																	style={{width: "100px", marginRight: "5px"}}
																	onChange={({target}) => this.setCustomerState({customer_hallbarhet_852_minimum: target.value})}
																	highlightErrors={hasFormValidationErrors}
																/> %
															</div>
														</div>
													</React.Fragment>
												)}

												<Checkbox style={{maxWidth: "740px", width: "100%", marginTop: "20px"}}
														  name="checkbox_hallbarhet_2088"
														  label="Ett finansiellt instrument i fråga om vilket kunden bestämmer att en viss minimiandel ska investeras i hållbara investeringar enligt definitionen i artikel 2.17 i Europaparlamentets och rådets förordning (EU) 2019/2088."
														  value="yes"
														  checked={customerState.checkbox_hallbarhet_2088 === "yes"}
														  onChange={checked => this.setCustomerState({checkbox_hallbarhet_2088: checked ? "yes" : "no"})}
														  highlightErrors={hasFormValidationErrors}
														  groupValid={
															  customerState.checkbox_hallbarhet_852 === "yes" ||
															  customerState.checkbox_hallbarhet_2088 === "yes" ||
															  customerState.checkbox_hallbarhet_customer === "yes" ||
															  customerState.checkbox_hallbarhet_art6 === "yes"
														  }
												/>

												{customerState.checkbox_hallbarhet_2088 === "yes" && (
													<React.Fragment>
														<div style={{
															paddingLeft: "35px",
															paddingBottom: "20px",
															display: "block"
														}}>
															<p style={{
																marginBottom: "5px",
																fontSize: "13px",
																fontWeight: "bold"
															}}>
																Minimiandel
															</p>
															<div style={{flexDirection: "row"}}>
																<SimpleTextField
																	type="number"
																	name={"customer_hallbarhet_2088_minimum"}
																	placeholder="Ange i %"
																	value={customerState.customer_hallbarhet_2088_minimum || ""}
																	textAlign="left"
																	validation={value => (value.length > 0 && value > 0 && value <= 100)}
																	style={{width: "100px", marginRight: "5px"}}
																	onChange={({target}) => this.setCustomerState({customer_hallbarhet_2088_minimum: target.value})}
																	highlightErrors={hasFormValidationErrors}
																/>
																%
															</div>
														</div>
													</React.Fragment>
												)}

												<Checkbox style={{maxWidth: "740px", width: "100%", marginTop: "20px"}}
														  name="checkbox_hallbarhet_customer"
														  label="Ett finansiellt instrument som beaktar huvudsakliga negativa konsekvenser för hållbarhetsfaktorer i fråga om vilket kunden bestämmer vilka kvalitativa eller kvantitativa inslag som ska påvisa att så sker."
														  value="yes"
														  checked={customerState.checkbox_hallbarhet_customer === "yes"}
														  onChange={checked => this.setCustomerState({checkbox_hallbarhet_customer: checked ? "yes" : "no"})}
														  highlightErrors={hasFormValidationErrors}
														  groupValid={
															  customerState.checkbox_hallbarhet_852 === "yes" ||
															  customerState.checkbox_hallbarhet_2088 === "yes" ||
															  customerState.checkbox_hallbarhet_customer === "yes" ||
															  customerState.checkbox_hallbarhet_art6 === "yes"
														  }
												/>

												{customerState.checkbox_hallbarhet_customer === "yes" && (
													<div style={{
														paddingLeft: "35px",
														paddingBottom: "20px",
														paddingTop: "20px",
														maxWidth: "740px",
														width: "100%",
														display: "block"
													}}>
														<TextView
															name="customer_hallbarhet_customer_text"
															label={`Beskrivning av kundens hållbarhetspreferenser (${1500 - customerState.customer_hallbarhet_customer_text.length} tecken kvar)`}
															value={customerState.customer_hallbarhet_customer_text}
															maxLength={1500}
															validation={value => value.length > 0}
															resize="vertical"
															onChange={({target}) => this.setCustomerState({customer_hallbarhet_customer_text: target.value})}
															fullWidth
															minHeight={250}
														/>
													</div>
												)}

												<Checkbox style={{maxWidth: "740px", width: "100%", marginTop: "20px"}}
														  name="checkbox_hallbarhet_art6"
														  label="Inget av ovan. Jag vill att hållbarhetsrisker ska integreras i investeringsbeslut enligt art. 6  i Europaparlamentets och rådets förordning (EU) 2019/2088 enligt följande."
														  value="yes"
														  checked={customerState.checkbox_hallbarhet_art6 === "yes"}
														  onChange={checked => this.setCustomerState({checkbox_hallbarhet_art6: checked ? "yes" : "no"})}
														  highlightErrors={hasFormValidationErrors}
														  groupValid={
															  customerState.checkbox_hallbarhet_852 === "yes" ||
															  customerState.checkbox_hallbarhet_2088 === "yes" ||
															  customerState.checkbox_hallbarhet_customer === "yes" ||
															  customerState.checkbox_hallbarhet_art6 === "yes"
														  }
												/>

												{customerState.checkbox_hallbarhet_art6 === "yes" && (
													<div style={{
														paddingLeft: "35px",
														paddingBottom: "20px",
														paddingTop: "20px",
														maxWidth: "740px",
														width: "100%",
														display: "block"
													}}>
														<TextView
															name="customer_hallbarhet_art6_description"
															label={`Beskriv (${250 - customerState.customer_hallbarhet_art6_description.length} tecken kvar)`}
															value={customerState.customer_hallbarhet_art6_description}
															maxLength={250}
															validation={value => value.length > 0}
															resize="vertical"
															onChange={({target}) => this.setCustomerState({customer_hallbarhet_art6_description: target.value})}
															fullWidth
															minHeight={250}
														/>
													</div>
												)}
											</React.Fragment>
										)}
									</div>

									<div className="h-line spacing" style={{marginTop: "30px"}}/>

									<OptionsList
										type="checkbox"
										description="Typ av insättning?"
										orientation="vertical"
										value={
											customerState.checkbox_deposit_type_monthly === "Ja" ||
											customerState.checkbox_deposit_type_regular === "Ja" ||
											customerState.checkbox_deposit_type_single === "Ja" ||
											customerState.checkbox_deposit_type_rearrange === "Ja" ?
												"filled" :
												""
										}
										options={[
											{
												name: "checkbox_deposit_type_monthly",
												label: "Månadssparande",
												value: customerState.checkbox_deposit_type_monthly === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_deposit_type_monthly === "Ja",
												onChange: checked => this.setCustomerState({checkbox_deposit_type_monthly: checked ? "Ja" : "Nej"})
											},
											{
												name: "checkbox_deposit_type_regular",
												label: "Regelbundna insättningar (minst 1 gång/år)",
												value: customerState.checkbox_deposit_type_regular === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_deposit_type_regular === "Ja",
												onChange: checked => this.setCustomerState({checkbox_deposit_type_regular: checked ? "Ja" : "Nej"})
											},
											{
												name: "checkbox_deposit_type_single",
												label: "Engångsinsättning",
												value: customerState.checkbox_deposit_type_single === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_deposit_type_single === "Ja",
												onChange: checked => this.setCustomerState({checkbox_deposit_type_single: checked ? "Ja" : "Nej"})
											},
											{
												name: "checkbox_deposit_type_rearrange",
												label: "Omplacering",
												value: customerState.checkbox_deposit_type_rearrange === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_deposit_type_rearrange === "Ja",
												onChange: checked => this.setCustomerState({checkbox_deposit_type_rearrange: checked ? "Ja" : "Nej"})
											}
										]}
										validation={value => (value && value === "filled") || false}
										highlightErrors={hasFormValidationErrors}
									/>

									<OptionsList
										type="checkbox"
										description="Vad är kundens syfte med investeringen?"
										orientation="vertical"
										style={{
											paddingTop: "20px"
										}}
										value={
											customerState.checkbox_customer_kyc_purpose_pension === "Ja" ||
											customerState.checkbox_customer_kyc_purpose_beneficiary === "Ja" ||
											customerState.checkbox_customer_kyc_purpose_speculation === "Ja" ||
											customerState.checkbox_customer_kyc_purpose_regpayments === "Ja" ||
											customerState.checkbox_customer_kyc_purpose_financial_security === "Ja" ||
											customerState.checkbox_customer_kyc_purpose_specsaving === "Ja" ?
												"filled" :
												""
										}
										options={[
											{
												name: "checkbox_customer_kyc_purpose_pension",
												label: "Öka pension",
												value: customerState.checkbox_customer_kyc_purpose_pension === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_customer_kyc_purpose_pension === "Ja",
												onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_pension: checked ? "Ja" : "Nej"})
											},
											{
												name: "checkbox_customer_kyc_purpose_beneficiary",
												label: "Trygga efterlevande",
												value: customerState.checkbox_customer_kyc_purpose_beneficiary === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_customer_kyc_purpose_beneficiary === "Ja",
												onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_beneficiary: checked ? "Ja" : "Nej"})
											},
											{
												name: "checkbox_customer_kyc_purpose_speculation",
												label: "Spekulation",
												value: customerState.checkbox_customer_kyc_purpose_speculation === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_customer_kyc_purpose_speculation === "Ja",
												onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_speculation: checked ? "Ja" : "Nej"})
											},
											{
												name: "checkbox_customer_kyc_purpose_regpayments",
												label: "Regelbunden utbetalning",
												value: customerState.checkbox_customer_kyc_purpose_regpayments === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_customer_kyc_purpose_regpayments === "Ja",
												onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_regpayments: checked ? "Ja" : "Nej"})
											},
											{
												name: "checkbox_customer_kyc_purpose_financial_security",
												label: "Ekonomisk buffert",
												value: customerState.checkbox_customer_kyc_purpose_financial_security === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_customer_kyc_purpose_financial_security === "Ja",
												onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_financial_security: checked ? "Ja" : "Nej"})
											},
											{
												name: "checkbox_customer_kyc_purpose_specsaving",
												label: "Specifikt sparmål (beskriv)",
												value: customerState.checkbox_customer_kyc_purpose_specsaving === "Ja" ? "Ja" : "Nej",
												checked: customerState.checkbox_customer_kyc_purpose_specsaving === "Ja",
												onChange: checked => this.setCustomerState({checkbox_customer_kyc_purpose_specsaving: checked ? "Ja" : "Nej"})
											}
										]}
										validation={value => (value && value === "filled") || false}
										highlightErrors={hasFormValidationErrors}
									/>
									{customerState.checkbox_customer_kyc_purpose_specsaving === "Ja" && (
										<TextField
											name="customer_kyc_purpose_savinggoal"
											label="Vänligen beskriv"
											value={customerState.customer_kyc_purpose_savinggoal}
											validation={this._textValidation}
											style={{marginTop: "10px"}}
											onChange={({target}) => this.setCustomerState({customer_kyc_purpose_savinggoal: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									)}
								</React.Fragment>
							)}
						</div>
					</div>
				</ContentBox>
			</div>
		);
	}

	_textValidation = (value) => (value.length > 0);

	_optionsValidation = (value) => ((value && value.length > 0) || false);
}


// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let initialState = {
		isFulfilled: false,
		customerState: {
			checkbox_advice_skip_placement: customer.getData("checkbox_advice_skip_placement") || "no",

			isCompany: customer.isCompany,
			checkbox_advice_riskinfo: customer.getData("checkbox_advice_riskinfo"),
			radio_advice_portfolio_risk: customer.getData("radio_advice_portfolio_risk"),
			radio_advice_riskpropensity: customer.getData("radio_advice_riskpropensity"),
			checkbox_advice_invpercentage: customer.getData("checkbox_advice_invpercentage"),
			radio_advice_savehoriz_newinv: customer.getData("radio_advice_savehoriz_newinv"),
			radio_advice_customer_riskrev: customer.getData("radio_advice_customer_riskrev"),

			checkbox_customer_kyc_purpose_pension: customer.getData("checkbox_customer_kyc_purpose_pension", null),
			checkbox_customer_kyc_purpose_regpayments: customer.getData("checkbox_customer_kyc_purpose_regpayments", null),
			checkbox_customer_kyc_purpose_financial_security: customer.getData("checkbox_customer_kyc_purpose_financial_security", null),
			checkbox_customer_kyc_purpose_relative: customer.getData("checkbox_customer_kyc_purpose_relative", null),
			checkbox_customer_kyc_purpose_beneficiary: customer.getData("checkbox_customer_kyc_purpose_beneficiary", null),
			checkbox_customer_kyc_purpose_speculation: customer.getData("checkbox_customer_kyc_purpose_speculation", null),
			checkbox_customer_kyc_purpose_specsaving: customer.getData("checkbox_customer_kyc_purpose_specsaving", null),
			customer_kyc_purpose_savinggoal: customer.getData("customer_kyc_purpose_savinggoal"),

			checkbox_deposit_type_monthly: customer.getData("checkbox_deposit_type_monthly"),
			checkbox_deposit_type_regular: customer.getData("checkbox_deposit_type_regular"),
			checkbox_deposit_type_single: customer.getData("checkbox_deposit_type_single"),
			checkbox_deposit_type_rearrange: customer.getData("checkbox_deposit_type_rearrange"),

			advice_profitgoal: customer.getData("advice_profitgoal") ? customer.getData("advice_profitgoal").replace(",", ".").replace("%", "") : "",

			checkbox_advice_invrisk_same: customer.getData("checkbox_advice_invrisk_same"),
			checkbox_advice_invrisk_changed: customer.getData("checkbox_advice_invrisk_changed"),
			checkbox_advice_invrisk_other: customer.getData("checkbox_advice_invrisk_other"),
			advice_invrisk_other_expl: customer.getData("advice_invrisk_other_expl"),

			checkbox_aml_origin_savings: customer.getData("checkbox_aml_origin_savings"),
			checkbox_aml_origin_salary: customer.getData("checkbox_aml_origin_salary"),
			checkbox_aml_origin_re: customer.getData("checkbox_aml_origin_re"),
			checkbox_aml_origin_savings_heritage: customer.getData("checkbox_aml_origin_savings_heritage"),
			checkbox_aml_origin_pension: customer.getData("checkbox_aml_origin_pension"),
			checkbox_aml_origin_capital_return: customer.getData("checkbox_aml_origin_capital_return"),
			checkbox_aml_origin_owncomp: customer.getData("checkbox_aml_origin_owncomp"),
			checkbox_aml_origin_companyexit: customer.getData("checkbox_aml_origin_companyexit"),
			checkbox_aml_origin_other: customer.getData("checkbox_aml_origin_other"),
			aml_origin_other_desc: customer.getData("aml_origin_other_desc"),

			radio_advice_savehoriz_newinvIndex: (
				customer.getData("radio_advice_savehoriz_newinv") === "<1" ? 0 :
					customer.getData("radio_advice_savehoriz_newinv") === "1-2" ? 1 :
						customer.getData("radio_advice_savehoriz_newinv") === "2-4" ? 2 :
							customer.getData("radio_advice_savehoriz_newinv") === "4-6" ? 3 :
								customer.getData("radio_advice_savehoriz_newinv") === "6-10" ? 4 :
									customer.getData("radio_advice_savehoriz_newinv") === ">10" ? 5 :
										-1
			),
			checkbox_advice_invpercentageIndex: (
				customer.getData("checkbox_advice_invpercentage") === "0-5" ? 0 :
					customer.getData("checkbox_advice_invpercentage") === "6-10" ? 1 :
						customer.getData("checkbox_advice_invpercentage") === "11-20" ? 2 :
							customer.getData("checkbox_advice_invpercentage") === ">20" ? 3 :
								-1
			),

			radio_hallbarhet_has_preferences: customer.getData("radio_hallbarhet_has_preferences"),
			checkbox_hallbarhet_852: customer.getData("checkbox_hallbarhet_852"),
			customer_hallbarhet_852_minimum: customer.getData("customer_hallbarhet_852_minimum"),
			checkbox_hallbarhet_2088: customer.getData("checkbox_hallbarhet_2088"),
			customer_hallbarhet_2088_minimum: customer.getData("customer_hallbarhet_2088_minimum"),
			checkbox_hallbarhet_customer: customer.getData("checkbox_hallbarhet_customer"),
			customer_hallbarhet_customer_text: customer.getData("customer_hallbarhet_customer_text"),
			checkbox_hallbarhet_art6: customer.getData("checkbox_hallbarhet_art6"),
			customer_hallbarhet_art6_description: customer.getData("customer_hallbarhet_art6_description"),
		}
	};

	if (initialState.customerState.advice_profitgoal !== "")
	{
		initialState.customerState.advice_profitgoal = Number(initialState.customerState.advice_profitgoal);
	}

	return initialState;
}

function _getFields()
{
	return [
		"checkbox_advice_skip_placement",
		"checkbox_advice_riskinfo",
		"radio_advice_customer_risk",
		"radio_advice_portfolio_risk",
		"advice_profitgoal",

		"aml_origin_other_desc",

		"checkbox_advice_invrisk_same",
		"checkbox_advice_invrisk_changed",
		"checkbox_advice_invrisk_other",
		"advice_invrisk_other_expl",

		"checkbox_deposit_type_monthly",
		"checkbox_deposit_type_regular",
		"checkbox_deposit_type_single",
		"checkbox_deposit_type_rearrange",
		"radio_advice_riskpropensity",
		"radio_advice_savehoriz_newinv",
		"checkbox_advice_invpercentage",
		"radio_advice_customer_riskrev",

		"checkbox_customer_kyc_purpose_beneficiary",
		"checkbox_customer_kyc_purpose_pension",
		"checkbox_customer_kyc_purpose_speculation",
		"checkbox_customer_kyc_purpose_regpayments",
		"checkbox_customer_kyc_purpose_financial_security",
		"checkbox_customer_kyc_purpose_specsaving",
		"customer_kyc_purpose_savinggoal",

		"radio_hallbarhet_has_preferences",
		"checkbox_hallbarhet_852",
		"customer_hallbarhet_852_minimum",
		"checkbox_hallbarhet_2088",
		"customer_hallbarhet_2088_minimum",
		"checkbox_hallbarhet_customer",
		"customer_hallbarhet_customer_text",
		"checkbox_hallbarhet_art6",
		"customer_hallbarhet_art6_description",
	];
}