// HELPERS
import Validator from "@classes/Validator";
import {validateEmail} from "@helpers/utils";

// OTHER
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";
import {inArray} from "@helpers/utils";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;
	const {isCompany} = customerState;

	// Person-/orgnr måste fyllas i
	if (customerState.ssnOrg.length <= 0)
	{
		return false;
	}

	// Förnamn måste fyllas i, det är detta som anges som företagsnamn
	if (customerState.firstName.length <= 0)
	{
		return false;
	}

	// Om det inte är ett företag måste efternamn också fyllas i
	if (!isCompany && customerState.lastName.length <= 0)
	{
		return false;
	}

	if (customerState.radio_advice_currentportfolio_risk.length <= 0)
	{
		return false;
	}

	if (customerState.checkbox_advice_savehoriz_tot.length <= 0)
	{
		return false;
	}

	// Adress måste fyllas i
	if (customerState.street.length <= 0 || customerState.zipCode.length <= 0 || customerState.city.length <= 0)
	{
		return false;
	}

	// E-mail måste stämma
	if (!validateEmail(customerState.email))
	{
		return false;
	}

	// Telefonnr är optional men formatet måste stämma om det fylls i
	if ((customerState.phoneNumber.length > 0 && customerState.phoneNumber !== "-" && !Validator.validatePhoneNum(customerState.phoneNumber)) ||
		(customerState.phoneNumberHome.length > 0 && customerState.phoneNumberHome !== "-" && !Validator.validatePhoneNum(customerState.phoneNumberHome)))
	{
		return false;
	}

	/**
	 * Privatpersoner måste ha land, medborgarskap och skatterättslig hemvist ifyllt
	 */
	if (!isCompany && (
		customerState.countryIndex < 0 ||
		customerState.citizenshipIndex < 0 ||
		customerState.taxCountryIndex < 0))
	{
		return false;
	}

	// För vems räkning investerar kunden måste kryssas
	if (customerState.checkbox_customer_kyc_inv_own_part !== 'yes' && !(customerState.checkbox_customer_kyc_inv_through_poa === 'ja' ||
		customerState.checkbox_customer_kyc_inv_through_poa === 'yes'))
	{
		return false;
	}

	// Bakgrund till investering måste fyllas i
	if (customerState.advice_notes.length <= 0) {
		return false;
	}

	// Åtm en kryssruta för Vad är ditt mål med investeringen? måste vara ikryssad
	if (!inArray("Ja", [
		customerState.checkbox_customer_goal_preserve,
		customerState.checkbox_customer_goal_cashflow,
		customerState.checkbox_customer_goal_hedge,
		customerState.checkbox_customer_goal_leverage,
		customerState.checkbox_customer_goal_grow
	])) {
		return false;
	}

	/**
	 * Om personen inte är myndig måste avsnitt om målsman fyllas i
	 */
	if (!isCompany && customerState.needsGuardian)
	{
		if (customerState.child_caretaker.length <= 0)
		{
			return false;
		}

		if (customerState.child_caretaker === "alone" && (
			customerState.caretaker_name_1.length <= 0 ||
			customerState.caretaker_ssn_1.length <= 0
		))
		{
			return false;
		}

		if (customerState.child_caretaker === "two" && (
			customerState.caretaker_name_2.length <= 0 ||
			customerState.caretaker_ssn_2.length <= 0 ||
			customerState.caretaker_name_3.length <= 0 ||
			customerState.caretaker_ssn_3.length <= 0
		))
		{
			return false;
		}
	}

	if (!isCompany)
	{
		// Om vi investerar via fullmakt måste fullmaktstagaren vara ifylld
		if (customerState.checkbox_customer_kyc_inv_through_poa === 'ja' &&
			!(customerState.customer_inv_poa_firstname.length > 0 &&
				customerState.customer_inv_poa_lastname.length > 0 &&
				customerState.customer_inv_poa_ssn.length > 0 &&
				Validator.validateSsn(customerState.customer_inv_poa_ssn)))
		{
			return false;
		}
	}
	else
	{
		// För företag måste vi ange fullmaktstagare eller firmatecknare
		if (customerState.companySignatories >= 1)
		{
			if (customerState.customer_inv_poa_firstname.length <= 0 ||
				customerState.customer_inv_poa_lastname.length <= 0 ||
				customerState.customer_inv_poa_ssn.length <= 0 ||
				!Validator.validateSsn(customerState.customer_inv_poa_ssn))
			{
				return false;
			}
		}

		if (customerState.companySignatories >= 2)
		{
			if (customerState.customer_inv_poa_firstname_2.length <= 0 ||
				customerState.customer_inv_poa_lastname_2.length <= 0 ||
				customerState.customer_inv_poa_ssn_2.length <= 0 ||
				!Validator.validateSsn(customerState.customer_inv_poa_ssn_2))
			{
				return false;
			}
		}

		if (customerState.companySignatories >= 3)
		{
			if (customerState.customer_inv_poa_firstname_3.length <= 0 ||
				customerState.customer_inv_poa_lastname_3.length <= 0 ||
				customerState.customer_inv_poa_ssn_3.length <= 0 ||
				!Validator.validateSsn(customerState.customer_inv_poa_ssn_3))
			{
				return false;
			}
		}

		if (customerState.companySignatories >= 4)
		{
			if (customerState.customer_inv_poa_firstname_4.length <= 0 ||
				customerState.customer_inv_poa_lastname_4.length <= 0 ||
				customerState.customer_inv_poa_ssn_4.length <= 0 ||
				!Validator.validateSsn(customerState.customer_inv_poa_ssn_4))
			{
				return false;
			}
		}
	}


	// TIN Måste vara ifyllt om skattehemvisten inte är Sverige
	if (!isCompany && customerState.taxCountry !== 'Sverige' && customerState.customer_TIN.length <= 0)
	{
		return false;
	}

	/**
	 * Företagsspecifik validering
	 */
	if (isCompany)
	{
		if (!customerState.leiCodeValid)
		{
			return false;
		}
	}

	return true;
}