import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import NumberField from "../../components/NumberField/NumberField";
import StackLayout from "@components/layouts/StackLayout/StackLayout";

// HELPERS
import {localeNumber, createClassName} from "@helpers/utils";

// OTHER
import fulfillment from "./Debts.fulfillment";


export default class Debts extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	setTableData(fields)
	{
		this.setState(prevState => ({
			customerState: {
				...prevState.customerState,
				tableData: {...prevState.customerState.tableData, ...fields}
			}
		}));
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	render()
	{
		const {state, props} = this;
		const {customer} = props;
		const {customerState, hasFormValidationErrors} = state;
		const classes = createClassName("Cob-view Cob-view4", {});

		const totalAssets = customer.getData("customer_asset_total") !== "" ? parseInt(customer.getData("customer_asset_total").replace(" SEK", "").split(' ').join(''), 10) : 0;

		let totalDebt = 0;
		totalDebt += customerState.customer_debt_study !== "" ? parseInt(customerState.customer_debt_study.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalDebt += customerState.customer_debt_realestate !== "" ? parseInt(customerState.customer_debt_realestate.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalDebt += customerState.customer_debt_other !== "" ? parseInt(customerState.customer_debt_other.replace(" SEK", "").split(' ').join(''), 10) : 0;

		let networth = totalAssets - totalDebt;

		let networthInsurance = networth;
		let pensionInsuranceValue = customer.getData("customer_asset_total_pension") !== "" ? parseInt(customer.getData("customer_asset_total_pension").replace(" SEK", "").split(' ').join(''), 10) : 0;
		if (pensionInsuranceValue > 0)
		{
			networthInsurance = networthInsurance - Math.round(pensionInsuranceValue / 2);
		}
		networthInsurance = localeNumber(networthInsurance, 0);

		if (networth < 0)
		{
			networth = "- " + localeNumber((networth * -1), 0);
		}
		else
		{
			networth = networth > 0 ? localeNumber(networth, 0) : networth;
		}

		totalDebt = totalDebt > 0 ? localeNumber(totalDebt, 0) : totalDebt;
		networthInsurance = networthInsurance > 0 ? localeNumber(networthInsurance, 0) : networthInsurance;

		let totalCosts = 0;
		totalCosts += customerState.customer_costs_loan_realestate !== "" ? parseInt(customerState.customer_costs_loan_realestate.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalCosts += customerState.customer_costs_loan_other !== "" ? parseInt(customerState.customer_costs_loan_other.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalCosts += customerState.customer_costs_re !== "" ? parseInt(customerState.customer_costs_re.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalCosts += customerState.customer_costs_other !== "" ? parseInt(customerState.customer_costs_other.replace(" SEK", "").split(' ').join(''), 10) : 0;

		totalCosts = totalCosts > 0 ? localeNumber(totalCosts, 0) : totalCosts;

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Ekonomisk situation</h2>

					<div className="Cob-view4-fields">
						<h3>Skulder</h3>
						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<NumberField
									name="customer_debt_study"
									label="Studieskulder"
									unit=" SEK"
									value={customerState.customer_debt_study || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_debt_study: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<NumberField
									name="customer_debt_realestate"
									label="Lån på fastighet"
									unit=" SEK"
									value={customerState.customer_debt_realestate || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_debt_realestate: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<NumberField
									name="customer_debt_loanprotection_re"
									label="Låneskydd: Lån på fastighet"
									unit=" SEK"
									value={customerState.customer_debt_loanprotection_re || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_debt_loanprotection_re: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<NumberField
									name="customer_debt_other"
									label="Övriga skulder"
									unit=" SEK"
									value={customerState.customer_debt_other || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_debt_other: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<NumberField
									name="customer_debt_loanprotection_other"
									label="Låneskydd: Övriga skulder"
									unit=" SEK"
									value={customerState.customer_debt_loanprotection_other || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_debt_loanprotection_other: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>


						<div className="Cob-TableSummary" style={{marginTop: "0"}}>
							<div className="Cob-TableSummary-container">
								<div className="Cob-TableSummaryField">
									<div className="Cob-TableSummaryField-label">Totala skulder</div>
									<div className="Cob-TableSummaryField-value">{totalDebt} SEK</div>
								</div>
								<div className="Cob-TableSummaryField">
									<div className="Cob-TableSummaryField-label">
										Totala tillgångar minus skulder
									</div>
									<div className="Cob-TableSummaryField-value">{networth} SEK</div>
								</div>
								<div className="Cob-TableSummaryField">
									<div className="Cob-TableSummaryField-label">
										Totala tillgångar (½ p-försäkringsvärde) minus skulder
									</div>
									<div className="Cob-TableSummaryField-value">{networthInsurance} SEK</div>
								</div>
							</div>
						</div>

						<input type="hidden" name="customer_debt_total" value={totalDebt + " SEK"}/>
						<input type="hidden" name="customer_networth" value={networth + " SEK"}/>
						<input type="hidden" name="customer_networth_insurance" value={networthInsurance + " SEK"}/>


						<h3 style={{marginTop: "30px"}}>Utgifter per månad</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<NumberField
									name="customer_costs_loan_realestate"
									label="Lånekostnader fastighet"
									unit=" SEK"
									value={customerState.customer_costs_loan_realestate || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_costs_loan_realestate: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<NumberField
									name="customer_costs_loan_other"
									label="Lånekostnader övrigt"
									unit=" SEK"
									value={customerState.customer_costs_loan_other || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_costs_loan_other: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<NumberField
									name="customer_costs_re"
									label="Hyra/avgift/fasta fastighetsavgifter"
									unit=" SEK"
									value={customerState.customer_costs_re || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_costs_re: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<NumberField
									name="customer_costs_other"
									label="Övriga fasta utgifter"
									unit=" SEK"
									value={customerState.customer_costs_other || ""}
									showSeparators={true}
									onChange={({target}) =>
									{
										this.setCustomerState({customer_costs_other: target.value.split(' ').join('')});
									}}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>

						<div className="Cob-TableSummary" style={{marginTop: "0"}}>
							<div className="Cob-TableSummary-container">
								<div className="Cob-TableSummaryField">
									<div className="Cob-TableSummaryField-label">Totala utgifter per månad</div>
									<div className="Cob-TableSummaryField-value">{totalCosts} SEK</div>
								</div>
							</div>
						</div>


						<input type="hidden" name="customer_costs_total" value={totalCosts + " SEK"}/>
					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	const newState = {
		customerState: {
			customer_debt_realestate: customer.getData("customer_debt_realestate") || "0",
			customer_debt_loanprotection_re: customer.getData("customer_debt_loanprotection_re") || "0",
			customer_debt_study: customer.getData("customer_debt_study") || "0",
			customer_debt_other: customer.getData("customer_debt_other") || "0",
			customer_debt_loanprotection_other: customer.getData("customer_debt_loanprotection_other") || "0",
			customer_costs_loan_realestate: customer.getData("customer_costs_loan_realestate") || "0",
			customer_costs_loan_other: customer.getData("customer_costs_loan_other") || "0",
			customer_costs_re: customer.getData("customer_costs_re") || "0",
			customer_costs_other: customer.getData("customer_costs_other") || "0"
		}
	};

	newState.isFulfilled = false;
	return newState;
}

function _getFields()
{
	return [
		"customer_debt_realestate",
		"customer_debt_loanprotection_re",
		"customer_debt_study",
		"customer_debt_other",
		"customer_debt_loanprotection_other",

		"customer_debt_total",
		"customer_networth",
		"customer_networth_insurance",

		"customer_costs_loan_realestate",
		"customer_costs_loan_other",
		"customer_costs_re",
		"customer_costs_other",
	];
}