import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState) {

	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	/**
	 * Antingen kryssar man alla tre rutor om att kunden förstått vad investeringen går ut på eller
	 * så kryssar man att man gjort en annan bedömning.
	 */
	if (!((
			customerState.checkbox_advice_suitability_goals === "Ja" &&
			customerState.checkbox_advice_suitability_risk === "Ja" &&
			customerState.checkbox_advice_suitability_knowledge === "Ja"
		) || customerState.checkbox_advice_suitability_other === "Ja")) {
		return false;
	}

	/**
	 * Om man kryssat annan bedömning måste textfältet fyllas i
	 */
	if (customerState.checkbox_advice_suitability_other === "Ja" && customerState.advice_suitability_knowledge_other.length <= 0) {
		return false;
	}

	return !!(
		customerState.advice_customer_suitability2.length > 0 &&
		customerState.advice_customer_suitability3.length > 0 &&
		customerState.advice_customer_suitability4.length > 0
	);
}