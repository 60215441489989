import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}
	state = mergeViewStateForFulfillment(state, prevState);

	const {customerState} = state;
	const {tableData} = customerState;

	if (customerState.isCompany)
	{
		/**
		 * Rådgivningen måste ha ett datum
		 */
		if (customerState.date.length <= 0)
		{
			return false;
		}

		// Rådgivningens utförande måste vara valt
		if (customerState.meetingFormatIndex === -1)
		{
			return false;
		}

		// Någon av boxarna för identitetskontroll måste kryssas
		if (customerState.checkbox_idcheck_id !== 'yes' &&
			customerState.checkbox_idcheck_drivinglic !== 'yes' &&
			customerState.checkbox_idcheck_passport !== 'yes' &&
			customerState.checkbox_idcheck_known !== 'Ja'
		)
		{
			return false;
		}
	}

	// Hur stor del av investeringen som kan förloras måste fyllas i
	if (customerState.checkbox_customer_risk_now.length <= 0)
	{
		return false;
	}

	// antal affärer sista åren för varje produkttyp måste vara vald
	if (tableData.radio_kyc_transactions_aktier.length <= 0 ||
		tableData.radio_kyc_transactions_fonder.length <= 0 ||
		tableData.radio_kyc_transactions_aio.length <= 0 ||
		tableData.radio_kyc_transactions_autocalls.length <= 0 ||
		tableData.radio_kyc_transactions_depaisk.length <= 0 ||
		tableData.radio_kyc_transactions_radgivning.length <= 0 ||
		tableData.radio_kyc_transactions_derivat.length <= 0 ||
		tableData.radio_kyc_transactions_index_products.length <= 0)
	{
		return false;
	}

	// kunskapsnivå om varje produkttyp måste vara vald
	if (tableData.radio_kyc_knowledge_aktier.length <= 0 ||
		tableData.radio_kyc_knowledge_fonder.length <= 0 ||
		tableData.radio_kyc_knowledge_aio.length <= 0 ||
		tableData.radio_kyc_knowledge_autocalls.length <= 0 ||
		tableData.radio_kyc_knowledge_depaisk.length <= 0 ||
		tableData.radio_kyc_knowledge_radgivning.length <= 0 ||
		tableData.radio_kyc_knowledge_derivat.length <= 0 ||
		tableData.radio_kyc_knowledge_index_products.length <= 0)
	{
		return false;
	}

	// genomsnittlig transaktion för varje produkttyp måste vara vald
	if (tableData.radio_aml_transaction_size_aktier.length <= 0 ||
		tableData.radio_aml_transaction_size_fonder.length <= 0 ||
		tableData.radio_aml_transaction_size_aio.length <= 0 ||
		tableData.radio_aml_transaction_size_autocalls.length <= 0 ||
		tableData.radio_aml_transaction_size_depaisk.length <= 0 ||
		tableData.radio_aml_transaction_size_radgivning.length <= 0 ||
		tableData.radio_aml_transaction_size_derivat.length <= 0 ||
		tableData.radio_aml_transaction_size_index_products.length <= 0)
	{
		return false;
	}

	// kunskapsnivå om varje försäkringstyp måste vara vald
	if (tableData.radio_kyc_knowledge_tins.length <= 0 ||
		tableData.radio_kyc_knowledge_pins.length <= 0 ||
		tableData.radio_kyc_knowledge_kins.length <= 0)
	{
		return false;
	}

	// kunskapsnivå om varje försäkringstyp måste vara vald
	if (tableData.radio_understands_aktier.length <= 0 ||
		tableData.radio_understands_fonder.length <= 0 ||
		tableData.radio_understands_aio.length <= 0 ||
		tableData.radio_understands_autocalls.length <= 0 ||
		tableData.radio_understands_derivat.length <= 0 ||
		tableData.radio_understands_depaisk.length <= 0 ||
		tableData.radio_understands_radgivning.length <= 0 ||
		tableData.radio_understands_index_products.length <= 0)
	{
		return false;
	}

	// kunskapsfrågorna måste vara besvarade
	if (customerState.checkbox_customer_kyc_avoid.length <= 0 ||
		customerState.checkbox_customer_kyc_loss.length <= 0 ||
		customerState.checkbox_customer_kyc_horisont.length <= 0)
	{
		return false;
	}

	// Mötesanteckningar måste vara ifyllt
	if (customerState.advice_notes2.length <= 0)
	{
		return false;
	}

	return true;
}