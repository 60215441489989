import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import NumberField from "../../../components/NumberField/NumberField";
import Select from "@components/Select/Select";
import StackLayout from "@components/layouts/StackLayout/StackLayout";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";

// OTHER
import fulfillment from "./FamilyAndAssets.fulfillment";
import TextField from "../../../../../components/TextField/TextField";
import OptionsList from "../../../components/OptionsList/OptionsList";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import CompanyReps from "../../CustomerInformation/Fair/subcomponents/CompanyReps/CompanyReps";
import {loop} from "../../../../../helpers/utils";
import Validator from "../../../../../classes/Validator";


export default class FamilyAndAssets extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	setTableData(fields)
	{
		this.setState(prevState => ({
			customerState: {
				...prevState.customerState,
				tableData: {...prevState.customerState.tableData, ...fields}
			}
		}));
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	render()
	{
		const {props, state} = this;
		const {customer} = props;
		const {customerState, hasFormValidationErrors} = state;
		const classes = createClassName("Cob-view Cob-view4", {});

		let companyTotalAssets = 0;
		if (customer.isCompany)
		{
			companyTotalAssets += customerState.customer_comp_assets_cash !== "" ? parseInt(customerState.customer_comp_assets_cash.replace(" SEK", "").split(' ').join(''), 10) : 0;
			companyTotalAssets += customerState.customer_comp_assets_fininstr !== "" ? parseInt(customerState.customer_comp_assets_fininstr.replace(" SEK", "").split(' ').join(''), 10) : 0;
			companyTotalAssets += customerState.customer_comp_assets_other !== "" ? parseInt(customerState.customer_comp_assets_other.replace(" SEK", "").split(' ').join(''), 10) : 0;
		}

		let shouldShowHuvudmanForJurPersSection = false;

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<input type="hidden" name={"company_benowner_firstname_0"} value=""/>
				<input type="hidden" name={"company_benowner_lastname_0"} value=""/>
				<input type="hidden" name={"company_benowner_sec_num_0"} value=""/>
				<input type="hidden" name={"company_benowner_percentage_0"} value=""/>

				<input type="hidden" name={"company_benowner_firstname_1"} value=""/>
				<input type="hidden" name={"company_benowner_lastname_1"} value=""/>
				<input type="hidden" name={"company_benowner_sec_num_1"} value=""/>
				<input type="hidden" name={"company_benowner_percentage_1"} value=""/>

				<input type="hidden" name={"company_benowner_firstname_2"} value=""/>
				<input type="hidden" name={"company_benowner_lastname_2"} value=""/>
				<input type="hidden" name={"company_benowner_sec_num_2"} value=""/>
				<input type="hidden" name={"company_benowner_percentage_2"} value=""/>

				<input type="hidden" name={"company_benowner_firstname_3"} value=""/>
				<input type="hidden" name={"company_benowner_lastname_3"} value=""/>
				<input type="hidden" name={"company_benowner_sec_num_3"} value=""/>
				<input type="hidden" name={"company_benowner_percentage_3"} value=""/>

				{customer.isCompany && (
					<React.Fragment>
						<input type="hidden" name="customer_savingsprofile_realestate" value="-"/>
						<input type="hidden" name="customer_savingsprofile_debt" value="-"/>
					</React.Fragment>
				)}

				<Modal visible={customerState.isUsPerson === "yes"} status="danger" title="FATCA">
					<React.Fragment>
						{!!state.errorMessage && <p>{state.errorMessage}</p>}

						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({
											  isUsPerson: this.state.customerState.isUsPerson === "yes" ? null : this.state.customerState.isUsPerson
										  })}/>
							<Modal.Button label="Avsluta rådgivning" appearance="primary"
										  onClick={this.props.onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>


				<Modal visible={customerState.isPep === "yes" || customerState.isPepPast === "yes" ||
								customerState.isBenPep === "yes" || customerState.isBenPepPast === "yes"}
					   status="danger" title="PEP">
					<React.Fragment>
						<p>
							Om kund eller eventuell huvudman är eller har varit person i politiskt utsatt ställning,
							alternativt har någon närstående eller medarbetare i politiskt utsatt ställning, behöver
							detta hanteras manuellt av Fair Investments.
						</p>

						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({
											  isPep: this.state.customerState.isPep === "yes" ? null : this.state.customerState.isPep,
											  isPepPast: this.state.customerState.isPepPast === "yes" ? null : this.state.customerState.isPepPast,
											  isBenPep: this.state.customerState.isBenPep === "yes" ? null : this.state.customerState.isBenPep,
											  isBenPepPast: this.state.customerState.isBenPepPast === "yes" ? null : this.state.customerState.isBenPepPast
										  })}/>
							<Modal.Button label="Avsluta rådgivning" appearance="primary"
										  onClick={this.props.onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>Övriga uppgifter om juridisk person</h2>

					{customer.isMinor && (
						<div style={{margin: "0 0 30px 0", padding: "10px", background: "#FFFFCC"}}>
							Då kunden är minderårig ska frågorna i rådgivningen besvaras av vårdnadshavare.
						</div>
					)}

					<div className="Cob-view4-fields">
						{!customer.isCompany && (
							<React.Fragment>

								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div>
										<Select
											name="customer_maritalstatus"
											label="Civilstånd"
											selectedIndex={customerState.maritalStatusIndex}
											options={[
												{label: "Ensamstående", value: "Ensamstående"},
												{label: "Sambo", value: "Sambo"},
												{label: "Gift/Reg. partner", value: "Gift/Reg. partner"},
												{label: "Änka/Änkling", value: "Änka/Änkling"},
												{label: "Skild", value: "Skild"}
											]}
											onChange={(option, maritalStatusIndex) => this.setCustomerState({
												maritalStatus: option.value,
												maritalStatusIndex
											})}
											highlightErrors={hasFormValidationErrors}
										/>

										{(customerState.maritalStatus === 'Sambo' ||
											customerState.maritalStatus === 'Gift/Reg. partner') && (
											<input type="hidden" name="radio_customer_partner" value="yes"/>
										)}

										{(customerState.maritalStatus === 'Ensamstående' ||
											customerState.maritalStatus === 'Skild' ||
											customerState.maritalStatus === 'Änka/Änkling') && (
											<input type="hidden" name="radio_customer_partner" value="no"/>
										)}
									</div>

									<div className="item">
										<Select
											label={i18n("cob", "total_service_obligation")}
											selectedIndex={customerState.customer_child_no_index}
											name="customer_child_no"
											options={[
												{label: "0", value: "0"},
												{label: "1", value: "1"},
												{label: "2", value: "2"},
												{label: "3", value: "3"},
												{label: "4+", value: "4+"}
											]}
											onChange={(option, customer_child_no_index) => this.setCustomerState({
												customer_child_no: option.value,
												customer_child_no_index
											})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>
								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div className="item">
										<TextField
											name="customer_employee"
											label="Arbetsgivare"
											validation={this._textValidation}
											value={customerState.customer_employee}
											onChange={({target}) => this.setCustomerState({customer_employee: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>

									<div className="item">
										<TextField
											name="customer_jobposition"
											label="Typ av tjänst/position"
											validation={this._textValidation}
											value={customerState.customer_jobposition}
											onChange={({target}) => this.setCustomerState({customer_jobposition: target.value})}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>

									<div className="item">
										<NumberField
											name="customer_salary"
											label="Kundens månadsinkomst före skatt"
											unit=" SEK"
											value={customerState.salary || ""}
											showSeparators={true}
											onChange={({target}) =>
											{
												this.setCustomerState({salary: target.value.split(' ').join('')});
											}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>
							</React.Fragment>
						)}

						{customer.isCompany && (
							<React.Fragment>
								<h3>Historiskt resultat</h3>
								<p style={{marginTop: "0", fontSize: "13px"}}>Ange värden före skatt.</p>
								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div>
										<NumberField
											name="customer_comp_histresult1"
											label="1 år sedan (senaste årsbokslut)"
											unit=" SEK"
											value={customerState.customer_comp_histresult1 || ""}
											allowNegative={true}
											showSeparators={true}
											onChange={({target}) =>
											{
												this.setCustomerState({customer_comp_histresult1: target.value.split(' ').join('')});
											}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<NumberField
											name="customer_comp_histresult2"
											label="2 år sedan"
											unit=" SEK"
											value={customerState.customer_comp_histresult2 || ""}
											allowNegative={true}
											showSeparators={true}
											onChange={({target}) =>
											{
												this.setCustomerState({customer_comp_histresult2: target.value.split(' ').join('')});
											}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<NumberField
											name="customer_comp_histresult3"
											label="3 år sedan"
											unit=" SEK"
											value={customerState.customer_comp_histresult3 || ""}
											allowNegative={true}
											showSeparators={true}
											onChange={({target}) =>
											{
												this.setCustomerState({customer_comp_histresult3: target.value.split(' ').join('')});
											}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>

								<h3>Uppskattat resultat kommande period</h3>
								<p style={{marginTop: "0", fontSize: "13px"}}>Ange värden före skatt.</p>
								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div>
										<NumberField
											name="customer_comp_budresult12m"
											label="12 månader (kommande årsbokslut)"
											unit=" SEK"
											value={customerState.customer_comp_budresult12m || ""}
											allowNegative={true}
											showSeparators={true}
											onChange={({target}) =>
											{
												this.setCustomerState({customer_comp_budresult12m: target.value.split(' ').join('')});
											}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<NumberField
											name="customer_comp_budresult24m"
											label="24 månader"
											unit=" SEK"
											value={customerState.customer_comp_budresult24m || ""}
											allowNegative={true}
											showSeparators={true}
											onChange={({target}) =>
											{
												this.setCustomerState({customer_comp_budresult24m: target.value.split(' ').join('')});
											}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>

								<h3>Tillgångar</h3>
								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div>
										<NumberField
											name="customer_comp_assets_cash"
											label="Bankkonto"
											unit=" SEK"
											value={customerState.customer_comp_assets_cash || ""}
											showSeparators={true}
											onChange={({target}) =>
											{
												this.setCustomerState({customer_comp_assets_cash: target.value.split(' ').join('')});
											}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<NumberField
											name="customer_comp_assets_fininstr"
											label="Finansiella instrument"
											unit=" SEK"
											value={customerState.customer_comp_assets_fininstr || ""}
											showSeparators={true}
											onChange={({target}) =>
											{
												this.setCustomerState({customer_comp_assets_fininstr: target.value.split(' ').join('')});
											}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<NumberField
											name="customer_comp_assets_other"
											label="Övrigt (t.ex. moms eller skattelättn.)"
											unit=" SEK"
											value={customerState.customer_comp_assets_other || ""}
											showSeparators={true}
											onChange={({target}) =>
											{
												this.setCustomerState({customer_comp_assets_other: target.value.split(' ').join('')});
											}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>

								<div className="Cob-TableSummary" style={{marginTop: "-10px"}}>
									<div className="Cob-TableSummary-container">
										<div className="Cob-TableSummaryField">
											<div className="Cob-TableSummaryField-label">
												Summa placeringsbara tillgångar
											</div>
											<div className="Cob-TableSummaryField-value">{companyTotalAssets} SEK</div>
										</div>
									</div>
								</div>
								<input type="hidden" name="customer_comp_assets_sum" value={companyTotalAssets + " SEK"}/>
							</React.Fragment>
						)}

						{customer.isCompany && (
							<React.Fragment>
								<input type="hidden" name="checkbox_customer_id_poa" value="yes"/>
								<input type="hidden" name="checkbox_customer_Certificate_of_registration" value="yes"/>
								<input type="hidden" name="checkbox_company_benowners_jurpers" value="Nej"/>

								<div className="h-line spacing"/>

								<h3>Information om verkliga huvudmän</h3>
								<p style={{margin: "10px 0 20px 0", maxWidth: "600px"}}>
									En fysisk person för vars räkning någon annan person handlar, eller om kunden är en
									juridisk
									person, den som utövar ett bestämmande inflytande över kunden.
								</p>

								<OptionsList
									style={{paddingBottom: "15px"}}
									type="radio"
									name="radio_has_ubo"
									value={customerState.hasUbo}
									description="Finns någon person som äger 25% eller mer av den juridiska personen eller någon framtida förmånstagare till 25% eller mer av stiftelsen?"
									options={[
										{
											label: i18n("general", "no"),
											value: "no",
											checked: customerState.hasUbo === "no"
										},
										{
											label: i18n("general", "yes"),
											value: "yes",
											checked: customerState.hasUbo === "yes"
										}
									]}
									onChange={(checked, hasUbo) => this.setCustomerState({hasUbo})}
									validation={(value) => ((value && value.length > 0) || false)}
									highlightErrors={hasFormValidationErrors}
								/>

								{customerState.hasUbo === "yes" && (
									<React.Fragment>
										<input type="hidden" name="checkbox_company_nobenowner" value="Nej"/>

										<CompanyReps
											reps={customerState.companyReps}
											secondary={false}
											onRepChange={this._onCompanyRepChange}
											activeAmount={customerState.companyRepsActiveAmount}
											highlightErrors={hasFormValidationErrors}
										/>

										<StackLayout fullWidth>
											<Button
												size="small"
												label={i18n("general", "remove_last")}
												appearance={customerState.companyRepsActiveAmount <= 0 ? "medium" : "danger"}
												disabled={customerState.companyRepsActiveAmount <= 0}
												onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount - 1})}
											/>
											<Button
												size="small"
												label="Lägg till huvudman"
												appearance={customerState.companyRepsActiveAmount >= 4 ? "medium" : "primary"}
												disabled={customerState.companyRepsActiveAmount >= 4}
												onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount + 1})}
												filled
											/>
										</StackLayout>

										{customerState.companyRepsActiveAmount > 0 && shouldShowHuvudmanForJurPersSection && (
											<React.Fragment>
												<div className="h-line spacing"/>
												<h3>Huvudmän hos ägarbolag</h3>
												<p style={{maxWidth: "600px"}}>
													Är någon av ovanstående huvudmän en juridisk person? Expandera i
													sådana fall nedanstående del och fyll i huvudmän hos de juridiska
													personerna.
												</p>

												<StackLayout fullWidth style={{marginTop: "10px"}}>
													<Button
														size="small"
														label="Fyll i huvudmän hos ägarbolag"
														appearance={customerState.showHuvudmanHosAgare ? "medium" : "primary"}
														disabled={customerState.showHuvudmanHosAgare}
														onClick={() => this.setCustomerState({showHuvudmanHosAgare: true})}
														filled
													/>
												</StackLayout>

												<div
													style={{display: customerState.showHuvudmanHosAgare ? "block" : "none"}}>
													<CompanyReps
														reps={customerState.companyReps}
														secondary={true}
														onRepChange={this._onCompanyRepChange}
														activeAmount={4}
													/>
												</div>
											</React.Fragment>
										)}
									</React.Fragment>
								)}

								{customerState.hasUbo === "no" && (
									<React.Fragment>
										<input type="hidden" name="checkbox_company_nobenowner" value="Ja"/>

										<p style={{
											maxWidth: "600px",
											margin: "0"
										}}>
											Om det inte finns någon verklig huvudman enligt ägande (25%) uppge en
											alternativ verklig huvudman,
											t.ex. VD eller styrelseordförande.
										</p>

										<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
											<div>
												<TextField
													name="company_benowner_alt_name"
													label="Namn"
													autocomplete="nope"
													value={customerState.company_benowner_alt_name}
													validation={value => value.length > 0}
													onChange={({target}) => this.setCustomerState({company_benowner_alt_name: target.value})}
													highlightErrors={hasFormValidationErrors}
												/>
											</div>
											<div>
												<TextField
													name="company_benowner_alt_ssn"
													label="Personnummer"
													autocomplete="nope"
													value={customerState.company_benowner_alt_ssn}
													validation={this._ssnValidation}
													onChange={({target}) => this.setCustomerState({company_benowner_alt_ssn: target.value})}
													highlightErrors={hasFormValidationErrors}
												/>
											</div>
											<div>
												<TextField
													name="company_benowner_alt_title"
													label="Titel"
													autocomplete="nope"
													validation={value => value.length > 0}
													value={customerState.company_benowner_alt_title}
													onChange={({target}) => this.setCustomerState({company_benowner_alt_title: target.value})}
													highlightErrors={hasFormValidationErrors}
												/>
											</div>
										</StackLayout>
									</React.Fragment>
								)}

								<div className="h-line spacing"/>

								<div className="blockingQuestions">
									<h3>PEP - Huvudmän / Alternativ huvudman</h3>

									<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
										<div>
											<OptionsList
												type="radio"
												name="radio_company_benowner_pep"
												description={"Är någon av ovanstående huvudmän/alternativ huvudman en person i politiskt utsatt ställning, närstående eller medarbetare till en sådan person?"}
												value={customerState.isBenPep}
												options={[
													{
														label: i18n("general", "no"),
														value: "no",
														checked: customerState.isBenPep === "no"
													},
													{
														label: i18n("general", "yes"),
														value: "yes",
														checked: customerState.isBenPep === "yes"
													}
												]}
												onChange={(checked, isBenPep) => this.setCustomerState({isBenPep})}
												validation={this._optionsValidation}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<OptionsList
												type="radio"
												name="radio_company_benowner_pep_past"
												description={"Har någon av ovanstående huvudmän/alternativ huvudman varit en person i politiskt utsatt ställning, närstående eller medarbetare till en sådan person?"}
												value={customerState.isBenPepPast}
												options={[
													{
														label: i18n("general", "no"),
														value: "no",
														checked: customerState.isBenPepPast === "no"
													},
													{
														label: i18n("general", "yes"),
														value: "yes",
														checked: customerState.isBenPepPast === "yes"
													}
												]}
												onChange={(checked, isBenPepPast) => this.setCustomerState({isBenPepPast})}
												validation={this._optionsValidation}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>
								</div>
							</React.Fragment>
						)}

						<div className="h-line spacing"/>
						<div className="blockingQuestions">
							<h3>FATCA</h3>
							<OptionsList
								style={{paddingBottom: "15px"}}
								type="radio"
								name="radio_customer_us_person"
								value={customerState.isUsPerson}
								description="Har kunden eller eventuellt förekommande verklig huvudman bosättning, green-card, skattskyldighet, adress i eller annan liknande anknytning till USA?"
								options={[
									{
										label: i18n("general", "no"),
										value: "no",
										checked: customerState.isUsPerson === "no"
									},
									{
										label: i18n("general", "yes"),
										value: "yes",
										checked: customerState.isUsPerson === "yes"
									}
								]}
								onChange={
									(checked, isUsPerson) => this.setState({
										errorMessage: isUsPerson === "yes" ? "Personer som lyder under FATCA måste hanteras manuellt. Vänligen kontakta Fair Investments." : null
									}, () => this.setCustomerState({isUsPerson}))
								}
								validation={this._optionsValidation}
								highlightErrors={hasFormValidationErrors}
							/>
						</div>

						{!customer.isCompany && (
							<React.Fragment>
								<div className="h-line spacing"/>
								<h3>PEP</h3>

								<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
									<div>
										<OptionsList
											type="radio"
											name="radio_customer_pep"
											value={customerState.isPep}
											description={"Är kunden, en närstående till kunden, eller någon av kundens medarbetare en person i politiskt utsatt ställning (PEP)?"}
											options={[
												{
													label: i18n("general", "no"),
													value: "no",
													checked: customerState.isPep === "no"
												},
												{
													label: i18n("general", "yes"),
													value: "yes",
													checked: customerState.isPep === "yes"
												}
											]}
											onChange={(checked, isPep) => this.setCustomerState({isPep})}
											validation={this._optionsValidation}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<OptionsList
											type="radio"
											name="radio_customer_pep_past"
											description={"Har kunden, en närstående till kunden, eller någon av kundens medarbetare varit en person i politiskt utsatt ställning (PEP)?"}
											value={customerState.isPepPast}
											options={[
												{
													label: i18n("general", "no"),
													value: "no",
													checked: customerState.isPepPast === "no"
												},
												{
													label: i18n("general", "yes"),
													value: "yes",
													checked: customerState.isPepPast === "yes"
												}
											]}
											onChange={(checked, isPepPast) => this.setCustomerState({isPepPast})}
											validation={this._optionsValidation}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>
							</React.Fragment>
						)}
					</div>
				</ContentBox>
			</div>
		);
	}

	_textValidation = (value) => (value.length > 0);

	_optionsValidation = (value) => ((value && value.length > 0) || false);

	/**
	 * SSN numbers need to be 12 digits and start with 19 or 20
	 */
	_ssnValidation = (value) => (!isNaN(value) && value.length === 12 &&
		(value.substring(0, 2) === "19" || value.substring(0, 2) === "20") && Validator.validateSsn(value)
	);

	_onCompanyRepChange = (rep, index) =>
	{
		const companyReps = this.state.customerState.companyReps.map((r, i) => i === index ? rep : r);
		this.setCustomerState({companyReps});
	};
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	const newState = {
		customerState: {
			isCompany: customer.isCompany,
			showHuvudmanHosAgare: false,

			isUsPerson: customer.getData("radio_customer_us_person", null),

			isPep: customer.getData("radio_customer_pep", null),
			isPepPast: customer.getData("radio_customer_pep_past", null),
			isBenPep: customer.getData("radio_company_benowner_pep", null),
			isBenPepPast: customer.getData("radio_company_benowner_pep_past", null),
			hasUbo: "",

			companyReps: customer.companyRepsFair,
			companyRepsActiveAmount: 0,
			company_benowner_alt_name: customer.getData("company_benowner_alt_name"),
			company_benowner_alt_ssn: customer.getData("company_benowner_alt_ssn"),
			company_benowner_alt_title: customer.getData("company_benowner_alt_title"),

			customer_comp_histresult1: customer.getData("customer_comp_histresult1"),
			customer_comp_histresult2: customer.getData("customer_comp_histresult2"),
			customer_comp_histresult3: customer.getData("customer_comp_histresult3"),

			customer_comp_budresult12m: customer.getData("customer_comp_budresult12m"),
			customer_comp_budresult24m: customer.getData("customer_comp_budresult24m"),

			customer_comp_assets_cash: customer.getData("customer_comp_assets_cash"),
			customer_comp_assets_fininstr: customer.getData("customer_comp_assets_fininstr"),
			customer_comp_assets_other: customer.getData("customer_comp_assets_other"),

			maritalStatus: customer.getData("customer_maritalstatus"),
			customer_partner: customer.getData("customer_partner"),

			monthlyPayment: customer.getData("customer_monthly_payment"),
			savingsProfileRealEstate: customer.isCompany ? "-" : customer.getData("customer_savingsprofile_realestate"),
			savingsProfileDebt: customer.isCompany ? "-" : customer.getData("customer_savingsprofile_debt"),
			tableData: customer.view4TableData,

			customer_employee: customer.getData("customer_employee"),
			customer_jobposition: customer.getData("customer_jobposition"),
			salary: customer.getData("customer_salary"),

			// Select indexes
			maritalStatusIndex: (
				customer.getData("customer_maritalstatus") === "Ensamstående" ? 0 :
					customer.getData("customer_maritalstatus") === "Sambo" ? 1 :
						customer.getData("customer_maritalstatus") === "Gift/Reg. partner" ? 2 :
							customer.getData("customer_maritalstatus") === "Änka/Änkling" ? 3 :
								customer.getData("customer_maritalstatus") === "Skild" ? 4 :
									-1
			),

			customer_child_no: customer.getData("customer_child_no"),
			customer_child_no_index: (
				customer.getData("customer_child_no") === "0" ? 0 :
					customer.getData("customer_child_no") === "1" ? 1 :
						customer.getData("customer_child_no") === "2" ? 2 :
							customer.getData("customer_child_no") === "3" ? 3 :
								customer.getData("customer_child_no") === "4+" ? 4 :
									-1
			)
		}
	};

	for (let i = 0; i < 4; i++)
	{
		const rep = newState.customerState.companyReps[i];
		if (rep.firstName)
		{
			newState.customerState.companyRepsActiveAmount++;
		}
		else
		{
			break;
		}
	}

	if (newState.customerState.companyRepsActiveAmount > 0)
	{
		newState.customerState.hasUbo = "yes";
	}
	else if (newState.customerState.company_benowner_alt_name.length > 0)
	{
		newState.customerState.hasUbo = "no";
	}

	// Om huvudman hos ägare är ifylld ska ytan vara expanderad
	if (customer.getData("company_benowner_firstname_4").length > 0 ||
		customer.getData("company_benowner_firstname_5").length > 0 ||
		customer.getData("company_benowner_firstname_6").length > 0 ||
		customer.getData("company_benowner_firstname_7").length > 0)
	{
		newState.customerState.showHuvudmanHosAgare = true;
	}

	newState.isFulfilled = false;
	return newState;
}

function _getFields()
{
	return [
		"customer_maritalstatus",
		"customer_comp_assets_sum",
		"customer_employee",
		"customer_jobposition",
		"customer_salary",
		"radio_customer_pep",
		"radio_customer_pep_past",
		"hasUbo",

		"company_benowner_alt_name",
		"company_benowner_alt_ssn",
		"company_benowner_alt_title",

		// company reps
		...(() =>
		{
			const returnValue = [];
			loop(8, i =>
			{
				returnValue.push("company_benowner_firstname_" + i);
				returnValue.push("company_benowner_lastname_" + i);
				returnValue.push("company_benowner_ssn_" + i);
				returnValue.push("company_benowner_percentage_" + i);
				returnValue.push("company_benowner_votepercentage_" + i);
			});
			return returnValue;
		})()
	];
}