import {mergeViewStateForFulfillment} from "../../../Cob.helpers";
import Validator from "../../../../../classes/Validator";

export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;
	const {isCompany, companyReps, companyRepsActiveAmount} = customerState;

	// US Person inte tillåten just nu
	if (customerState.isUsPerson !== "no")
	{
		return false;
	}

	if (isCompany)
	{
		// Historiskt resultat
		if (customerState.customer_comp_histresult1.length <= 0 ||
			customerState.customer_comp_histresult2.length <= 0 ||
			customerState.customer_comp_histresult3.length <= 0)
		{
			return false;
		}

		// Uppskattat resultat
		if (customerState.customer_comp_budresult12m.length <= 0 ||
			customerState.customer_comp_budresult24m.length <= 0)
		{
			return false;
		}

		// Tillgångar
		if (customerState.customer_comp_assets_cash.length <= 0 ||
			customerState.customer_comp_assets_fininstr.length <= 0 ||
			customerState.customer_comp_assets_other.length <= 0)
		{
			return false;
		}

		// UBO option needs to be selected
		if (customerState.hasUbo !== "yes" && customerState.hasUbo !== "no")
		{
			return false;
		}

		/**
		 * Ingen huvudman, då måste alternativ huvudman vara ifyllt
		 */
		if (customerState.hasUbo === "no")
		{
			if (customerState.company_benowner_alt_name.length <= 0 ||
				customerState.company_benowner_alt_ssn.length <= 0 ||
				customerState.company_benowner_alt_title.length <= 0 ||
				!Validator.validateSsn(customerState.company_benowner_alt_ssn))
			{
				return false;
			}
		}

		if (customerState.hasUbo === "yes" && customerState.companyRepsActiveAmount <= 0)
		{
			return false;
		}

		/**
		 * PEP-frågorna för huvudman måste också kryssas nej alltid
		 */
		if (!(customerState.isBenPep === "no" && customerState.isBenPepPast === "no"))
		{
			return false;
		}

		if (customerState.hasUbo === "yes")
		{
			return _validateCompanyRep(companyReps[0], companyRepsActiveAmount < 1) &&
				_validateCompanyRep(companyReps[1], companyRepsActiveAmount < 2) &&
				_validateCompanyRep(companyReps[2], companyRepsActiveAmount < 3) &&
				_validateCompanyRep(companyReps[3], companyRepsActiveAmount < 4) &&
				_validateCompanyRep(companyReps[4], companyRepsActiveAmount < 5) &&
				_validateCompanyRep(companyReps[5], companyRepsActiveAmount < 6);
		}

		return true;
	}

	// PEP - båda frågorna måste kryssas nej
	if (!isCompany && !(customerState.isPep === "no" && customerState.isPepPast === "no"))
	{
		return false;
	}

	// Civilstånd
	if (customerState.maritalStatus.length <= 0)
	{
		return false;
	}

	// Antal barn samt deras personinfo
	if (customerState.customer_child_no.length <= 0)
	{
		return false;
	}

	// Månadsinkomst före skatt måste fyllas i
	if (customerState.salary.length <= 0)
	{
		return false;
	}

	// Arbetsgivare och typ av tjänst
	if (customerState.customer_employee.length <= 0 || customerState.customer_jobposition.length <= 0)
	{
		return false;
	}

	return true;
}


function _validateCompanyRep(rep, fallbackValue = false)
{
	if (fallbackValue)
	{
		return true;
	}

	if (!rep.isCompany)
	{
		if (!Validator.validateSsn(rep.ssn))
		{
			return false;
		}

		return !!((
			rep.firstName.length > 0
			&& rep.lastName.length > 0
			&& rep.ssn.length > 0
		));
	}

	if (rep.firstName.length <= 0 || rep.ssn.length <= 0)
	{
		return false;
	}

	let ssnForValidation = rep.ssn;
	if (ssnForValidation.substring(0, 2) === "16")
	{
		ssnForValidation = ssnForValidation.substring(2);
	}

	if (!Validator.validateOrgNum(ssnForValidation))
	{
		return false;
	}

	return true;
}