import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";

// HELPERS
import {createClassName} from "@helpers/utils";

// OTHER
import fulfillment from "./BasicProductSelection.fulfillment";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import TextView from "../../../../../components/TextView/TextView";
import Select from "../../../../../components/Select/Select";
import TextField from "../../../../../components/TextField/TextField";
import NumberField from "../../../components/NumberField/NumberField";
import SimpleTextField from "../../../components/SimpleTextField/SimpleTextField";
import Validator from "../../../../../classes/Validator";
import OptionsList from "../../../components/OptionsList/OptionsList";
import AddonPlacement from "./subcomponents/AddonPlacement";
import {localeNumber} from "../../../../../helpers/utils";


export default class BasicProductSelectionView extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);
	investmentAdviceProducts = [
		{
			label: "Camp B", value: "Camp B"
		},
		{
			label: "Annan (ange)", value: "Annan"
		}];

	isFulfilled(state)
	{
		return fulfillment.call(this, state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	getAllocationFields()
	{
		const {state} = this;
		const {customerState} = state;

		let feeGuru = customerState.allocation_fee_guru_revenueshare && (
			customerState.allocation_fee_guru_revenueshare === "33" ? "standard" : "reduced"
		);

		let feeSpets = customerState.allocation_fee_spets_revenueshare && (
			customerState.allocation_fee_spets_revenueshare === "33" ? "standard" : "reduced"
		);

		if (customerState.radio_spets_fixed_fee === "yes")
		{
			feeSpets = "fixed";
		}

		if (customerState.radio_guru_fixed_fee === "yes")
		{
			feeGuru = "fixed";
		}

		let amountGuru = customerState.allocation_amount_guru;
		let amountSpets = customerState.allocation_amount_spets;

		if (amountGuru.length > 0 && amountGuru.indexOf(" SEK") <= 0)
		{
			amountGuru = localeNumber(amountGuru, 0) + " SEK";
		}

		if (amountSpets.length > 0 && amountSpets.indexOf(" SEK") <= 0)
		{
			amountSpets = localeNumber(amountSpets, 0) + " SEK";
		}

		if (customerState.checkbox_product_guru && !customerState.checkbox_product_spets)
		{
			return {
				allocation_amount_first: amountGuru,
				allocation_product_first: "Flexibel",
				allocation_product_bilaga_first: "",
				radio_allocation_fee_first: feeGuru,
				allocation_fee_revshare_first: feeGuru === "fixed" ? "" : (customerState.allocation_fee_guru_revenueshare && (customerState.allocation_fee_guru_revenueshare === "0" || customerState.allocation_fee_guru_revenueshare >= "33") ? "" : customerState.allocation_fee_guru_revenueshare),
				allocation_fee_rebate_first: feeGuru === "fixed" ? "" : (customerState.allocation_fee_guru_rebate && customerState.allocation_fee_guru_rebate === "0" ? "" : customerState.allocation_fee_guru_rebate),

				allocation_amount_second: "",
				allocation_product_second: "",
				allocation_product_bilaga_second: "",
				radio_allocation_fee_second: "",
				allocation_fee_revshare_second: "",
				allocation_fee_rebate_second: ""
			}
		}

		if (!customerState.checkbox_product_guru && customerState.checkbox_product_spets)
		{
			return {
				allocation_amount_first: amountSpets,
				allocation_product_first: "Global",
				allocation_product_bilaga_first: "",
				radio_allocation_fee_first: feeSpets,
				allocation_fee_revshare_first: feeSpets === "fixed" ? "" : (customerState.allocation_fee_spets_revenueshare && (customerState.allocation_fee_spets_revenueshare === "0" || customerState.allocation_fee_spets_revenueshare === "33") ? "" : customerState.allocation_fee_spets_revenueshare),
				allocation_fee_rebate_first: feeSpets === "fixed" ? "" : (customerState.allocation_fee_spets_rebate && customerState.allocation_fee_spets_rebate === "0" ? "" : customerState.allocation_fee_spets_rebate),

				allocation_amount_second: "",
				allocation_product_second: "",
				allocation_product_bilaga_second: "",
				radio_allocation_fee_second: "",
				allocation_fee_revshare_second: "",
				allocation_fee_rebate_second: ""
			}
		}

		if (customerState.checkbox_product_guru && customerState.checkbox_product_spets)
		{
			return {
				allocation_amount_first: amountGuru,
				allocation_product_first: "Flexibel",
				allocation_product_bilaga_first: "",
				radio_allocation_fee_first: feeGuru,
				allocation_fee_revshare_first: feeGuru === "fixed" ? "" : (customerState.allocation_fee_guru_revenueshare && (customerState.allocation_fee_guru_revenueshare === "0" || customerState.allocation_fee_guru_revenueshare === "33") ? "" : customerState.allocation_fee_guru_revenueshare),
				allocation_fee_rebate_first: feeGuru === "fixed" ? "" : (customerState.allocation_fee_guru_rebate && customerState.allocation_fee_guru_rebate === "0" ? "" : customerState.allocation_fee_guru_rebate),

				allocation_amount_second: amountSpets,
				allocation_product_second: "Global",
				allocation_product_bilaga_second: "",
				radio_allocation_fee_second: feeSpets,
				allocation_fee_revshare_second: feeSpets === "fixed" ? "" : (customerState.allocation_fee_spets_revenueshare && (customerState.allocation_fee_spets_revenueshare === "0" || customerState.allocation_fee_spets_revenueshare === "33") ? "" : customerState.allocation_fee_spets_revenueshare),
				allocation_fee_rebate_second: feeSpets === "fixed" ? "" : (customerState.allocation_fee_spets_rebate && customerState.allocation_fee_spets_rebate === "0" ? "" : customerState.allocation_fee_spets_rebate),
			}
		}

		return {
			allocation_amount_first: "",
			allocation_product_first: "",
			allocation_product_bilaga_first: "",
			radio_allocation_fee_first: "",
			allocation_fee_revshare_first: "",
			allocation_fee_rebate_first: "",

			allocation_amount_second: "",
			allocation_product_second: "",
			allocation_product_bilaga_second: "",
			radio_allocation_fee_second: "",
			allocation_fee_revshare_second: "",
			allocation_fee_rebate_second: ""
		}
	}

	render()
	{
		const {state} = this;
		const {customer, availableBanks} = this.props;
		const {customerState} = state;

		const hasFormValidationErrors = !this.state.isFulfilled && this.state.hasFormValidationErrors;
		const classes = createClassName("Cob-view Cob-basic-product-selection", {});

		/**
		 * Set up the dropdown with selectable banks.
		 */
		let availableBankOptions = [];
		for (const bankName in availableBanks)
		{
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName))
			{
				continue;
			}
			availableBankOptions.push({
				label: bankName,
				value: bankName
			});
		}

		availableBankOptions.push({
			label: "Övriga",
			value: "-"
		});

		let isIskTypeSelected =
			(customerState.checkbox_product_guru && customerState.allocation_amount_guru.length > 0 && customerState.checkbox_accounttype_guru === "isk") ||
			(customerState.checkbox_product_guru && customerState.allocation_amount_guru.length > 0 && customerState.checkbox_accounttype_guru === 1) ||

			(customerState.checkbox_product_spets && customerState.allocation_amount_spets.length > 0 && customerState.checkbox_accounttype_spets === "isk") ||
			(customerState.checkbox_product_spets && customerState.allocation_amount_spets.length > 0 && customerState.checkbox_accounttype_spets === 1) ||

			(customerState.checkbox_product_svan && customerState.allocation_amount_svan.length > 0 && customerState.checkbox_accounttype_svan === "isk") ||
			(customerState.checkbox_product_svan && customerState.allocation_amount_svan.length > 0 && customerState.checkbox_accounttype_svan === 1) ||

			(customerState.checkbox_product_other && customerState.allocation_amount_other.length > 0 && customerState.checkbox_accounttype_other === "isk") ||
			(customerState.checkbox_product_other && customerState.allocation_amount_other.length > 0 && customerState.checkbox_accounttype_other === 1) ||

			(customerState.checkbox_product_advice && customerState.allocation_amount_advice.length > 0 && customerState.checkbox_accounttype_advice === "isk") ||
			(customerState.checkbox_product_advice && customerState.allocation_amount_advice.length > 0 && customerState.checkbox_accounttype_advice === 1);

		let accountTypeOptions = [
			{label: "Depå hos Fair", value: "naked"},
			{label: "ISK hos Fair", value: "isk"},
			{label: "Stiftelse", value: "trust"},
			{label: "P-försäkring", value: "pinsurance"},
			{label: "T-försäkring", value: "tinsurance"},
			{label: "K-försäkring", value: "kinsurance"}
		];

		// ISK not available for companies
		if (customer.isCompany)
		{
			isIskTypeSelected = false;
			accountTypeOptions = [
				{label: "Depå hos Fair", value: "naked"},
				{label: "Stiftelse", value: "trust"},
				{label: "P-försäkring", value: "pinsurance"},
				{label: "T-försäkring", value: "tinsurance"},
				{label: "K-försäkring", value: "kinsurance"}
			];
		}

		let totalAmountInvested = 0;
		totalAmountInvested += customerState.checkbox_product_spets && customerState.allocation_amount_spets !== "" ? parseInt(customerState.allocation_amount_spets.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += customerState.checkbox_product_guru && customerState.allocation_amount_guru !== "" ? parseInt(customerState.allocation_amount_guru.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += customerState.checkbox_product_other && customerState.allocation_amount_other !== "" ? parseInt(customerState.allocation_amount_other.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += customerState.checkbox_product_svan && customerState.allocation_amount_svan !== "" ? parseInt(customerState.allocation_amount_svan.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += customerState.checkbox_product_advice && customerState.allocation_amount_advice !== "" ? parseInt(customerState.allocation_amount_advice.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested = localeNumber(totalAmountInvested, 0);

		const allocationFields = this.getAllocationFields();

		let spetsAmount = 0;
		if (customerState.checkbox_product_spets && customerState.allocation_amount_spets.length > 0)
		{
			spetsAmount = parseInt(customerState.allocation_amount_spets.split(' ').join('').replace("SEK", ""), 10);
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Förvaltningstjänster</h2>

					<OptionsList
						type="radio"
						name="radio_product_selection_mode"
						style={{maxWidth: "none"}}
						value={customerState.radio_product_selection_mode}
						options={[
							{
								label: "Ny placering",
								value: "new",
								disabled: customerState.force_no_placement,
								checked: customerState.radio_product_selection_mode === "new" && !customerState.force_no_placement
							},
							{
								label: "Tilläggsplacering / Byte av avgiftsmodell",
								value: "addonchange",
								disabled: customerState.force_no_placement,
								checked: customerState.radio_product_selection_mode === "addonchange" && !customerState.force_no_placement
							},
							{
								label: "Ingen placering",
								value: "nochange",
								disabled: !customerState.force_no_placement,
								checked: customerState.force_no_placement
							}
						]}
						onChange={(checked, radio_product_selection_mode) =>
						{
							if (!customerState.force_no_placement)
							{
								this.setCustomerState({radio_product_selection_mode});
							}
						}}
						validation={this._optionsValidation}
						highlightErrors={hasFormValidationErrors}
					/>

					{customerState.radio_product_selection_mode !== "nochange" && (
						<div className="h-line spacing" style={{marginTop: "25px"}}/>
					)}

					{customerState.radio_product_selection_mode === "" && (
						<div style={{fontStyle: "italic", fontSize: "18px", display: "block", width: "100%"}}>
							Var god välj förvaltningstjänst.
						</div>
					)}

					{(customerState.radio_product_selection_mode === "new" ||
						customerState.radio_product_selection_mode === "addonchange") && (
						<div style={{alignItems: "flex-start", alignSelf: "flex-start"}}>
							<Checkbox
								type="checkbox"
								name="checkbox_customer_is_audience"
								label="Rådgivaren/förvaltaren har säkerställt att kunden tillhör produkten/produkternas målgrupp"
								value={customerState.checkbox_customer_is_audience}
								checked={customerState.checkbox_customer_is_audience === "Ja"}
								onChange={checked => this.setCustomerState({checkbox_customer_is_audience: checked ? "Ja" : "nej"})}
								highlightErrors={hasFormValidationErrors}
							/>
						</div>
					)}

					{customerState.radio_product_selection_mode === "addonchange" && (
						<AddonPlacement setAppState={this.props.setAppState}
										customer={customer}
										onChange={this._AddonPlacementFulfillment}
										highlightErrors={hasFormValidationErrors}
						/>
					)}

					{customerState.radio_product_selection_mode === "new" && (
						<div className="Cob-basic-product-selection-fields">
							<h4>Förvaltningstjänster som tecknas vid detta tillfälle</h4>

							<Checkbox
								style={{marginTop: "20px", paddingRight: "30px"}}
								type="checkbox"
								name="checkbox_product_spets"
								label="Fair Globalförvaltning"
								value={customerState.checkbox_product_spets}
								checked={customerState.checkbox_product_spets}
								onChange={checked => this.setCustomerState({checkbox_product_spets: checked})}
								highlightErrors={hasFormValidationErrors}
								groupValid={
									customerState.checkbox_product_spets ||
									customerState.checkbox_product_guru ||
									customerState.checkbox_product_svan ||
									customerState.checkbox_product_advice
								}
							/>

							{customerState.checkbox_product_spets && (
								<div className="product">
									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Belopp att investera</p>
											<NumberField
												name="allocation_amount_spets"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_amount_spets || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_amount_spets: target.value.split(' ').join('')});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
											<Select
												name="radio_allocation_perc_spets"
												label="Ange i procent"
												selectedIndex={customerState.radio_allocation_perc_spetsIndex}
												options={[
													{label: "0-5%", value: "0-5"},
													{label: "6-10%", value: "6-10"},
													{label: "11-20%", value: "11-20"},
													{label: ">20%", value: ">20"}
												]}
												onChange={(checked, radio_allocation_perc_spets) => this.setCustomerState({radio_allocation_perc_spets})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Risk enligt "Information om riskklasser"</p>
											<strong style={{fontSize: "16px"}}>4</strong>
											<input type="hidden" name="radio_allocation_risk_spets" value="4"/>
										</div>
										<div>
											<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
											<Select
												name="checkbox_accounttype_spets"
												label="Ange"
												selectedIndex={customerState.checkbox_accounttype_spetsIndex}
												options={accountTypeOptions}
												onChange={(checked, checkbox_accounttype_spets) => this.setCustomerState({checkbox_accounttype_spets})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Placeringshorisont</p>
											<strong>Minst 5 år</strong>
										</div>
									</StackLayout>

									{customerState.radio_spets_fixed_fee === "yes" && (
										<React.Fragment>
											<input type="hidden" name="allocation_fee_spets" value=""/>
											<input type="hidden" name="allocation_fee_spets_revenueshare" value=""/>
											<input type="hidden" name="allocation_fee_spets_rebate" value=""/>
											<input type="hidden" name="radio_allocation_fee_spets" value="fixed"/>
											<input type="hidden" name="account_advice_partnerfee_spets" value=""/>
										</React.Fragment>
									)}

									{customerState.radio_spets_fixed_fee === "no" && (
										<React.Fragment>
											<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
												<div>
													<p className="fieldLabel">
														Vinstdelning
													</p>
													<div style={{flexDirection: "row"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.allocation_fee_spets_revenueshare || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({allocation_fee_spets_revenueshare: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<p className="fieldLabel" style={{marginTop: "10px"}}>
														Förvaltningsrabatt
													</p>
													<div style={{flexDirection: "row", marginBottom: "20px"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.allocation_fee_spets_rebate || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({allocation_fee_spets_rebate: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<input type="hidden" name="allocation_fee_spets"
														   value={customerState.allocation_fee_spets_revenueshare
															   ? customerState.allocation_fee_spets_revenueshare
															   : ""}/>

													<input type="hidden" name="allocation_fee_spets_revenueshare"
														   value={customerState.allocation_fee_spets_revenueshare
															   ? customerState.allocation_fee_spets_revenueshare
															   : ""}/>

													<input type="hidden" name="allocation_fee_spets_rebate"
														   value={customerState.allocation_fee_spets_rebate
															   ? customerState.allocation_fee_spets_rebate
															   : ""}/>

													<input type="hidden" name="radio_allocation_fee_spets"
														   value={customerState.allocation_fee_spets_revenueshare && customerState.allocation_fee_spets_revenueshare === "33"
															   ? "standard"
															   : "reduced"}/>
												</div>

												<div>
													<p className="fieldLabel">
														Ersättning till rådgivaren <br/>(i % av förvaltningsavgift)
													</p>

													<div style={{flexDirection: "row"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.account_advice_partnerfee_spets || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({account_advice_partnerfee_spets: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<input type="hidden" name="account_advice_partnerfee_spets"
														   value={customerState.account_advice_partnerfee_spets ? customerState.account_advice_partnerfee_spets : ""}/>

												</div>
											</StackLayout>
										</React.Fragment>
									)}
									{/* TODO: Add validation for these fields, needs at least one checked?*/}
									<div style={{alignItems: "flex-start", width: "100%", margin: "10px 0 0 0"}}>
										<Checkbox
											type="checkbox"
											name="checkbox_spets_mjukstart"
											value={customerState.checkbox_spets_mjukstart === "yes" ? "yes" : "no"}
											label={"Mjukstart"}
											checked={customerState.checkbox_spets_mjukstart === "yes"}
											onChange={checked => this.setCustomerState({checkbox_spets_mjukstart: checked ? "yes" : "no"})}
										/>
									</div>

									<div style={{alignItems: "flex-start", width: "100%", margin: "20px 0 0 0"}}>
										<Checkbox
											type="checkbox"
											name="checkbox_special_terms_agreed"
											value={customerState.checkbox_special_terms_agreed === "yes" ? "yes" : "no"}
											label={"Överrenskommelse om avvikande villkor"}
											checked={customerState.checkbox_special_terms_agreed === "yes"}
											onChange={checked => this.setCustomerState({checkbox_special_terms_agreed: checked ? "yes" : "no"})}
										/>
									</div>

									<div style={{alignItems: "flex-start", width: "100%", margin: "20px 0 0 0"}}>
										<Checkbox
											type="checkbox"
											name="allocation_fxhedge_spets"
											value={customerState.allocation_fxhedge_spets === "yes" ? "yes" : "no"}
											label={"Erbjudande om valutaskydd i Fair Globalförvaltning"}
											checked={customerState.allocation_fxhedge_spets === "yes"}
											onChange={checked => this.setCustomerState({allocation_fxhedge_spets: checked ? "yes" : "no"})}
										/>
										<p style={{width: "600px", margin: "5px 0 10px 34px", lineHeight: "19px"}}>
											Kunden vill ta del av tillägget om valutaskydd inom ramen för
											Fair Globalförvaltning. Kunden har tagit del av “9. Erbjudande om valutaskydd”
											och “11. Avgifter, kostnader och Evigt Highwater Mark” i Bilaga 4 och är
											därför införstådd med hur upplägget med valutaskyddet fungerar samt att
											transaktionskostnaderna relaterade till valutaskyddet kan komma att uppgå
											till 1,9% årligen av det skyddade beloppet.
										</p>
									</div>

									<div style={{alignItems: "flex-start", width: "100%"}}>
										<h4>Verkställighet av förvaltning</h4>
										<p style={{width: "600px", margin: "0 0 10px 0", lineHeight: "19px"}}>
											Insättningsbeloppet ska enligt överenskommelse överföras senast tio (10)
											bankdagar efter undertecknande av Avtalet.
										</p>
										<Checkbox
											type="checkbox"
											name="allocation_separate_deposit"
											value={customerState.allocation_separate_deposit === "yes" ? "yes" : "no"}
											label={"Kunden överför insättningsbeloppet vid uppdelade tillfällen"}
											checked={customerState.allocation_separate_deposit === "yes"}
											onChange={checked => this.setCustomerState({allocation_separate_deposit: checked ? "yes" : "no"})}
										/>

										{customerState.allocation_separate_deposit === "yes" && (
											<React.Fragment>
												<p style={{
													width: "600px",
													margin: "10px 0 5px 34px",
													lineHeight: "19px"
												}}>
													Ange hur stort belopp som ska överföras vid det första
													insättningstillfället:
												</p>

												<StackLayout className="Cob-fields-collection per-row-3" rowWrap
															 fullWidth>
													<div style={{marginLeft: "24px"}}>
														<NumberField
															name="allocation_amount_initial"
															label="Ange i SEK"
															unit=" SEK"
															value={customerState.allocation_amount_initial || ""}
															showSeparators={true}
															validation={value => (value.length > 0 && parseInt(value.split(' ').join('').replace("SEK", ""), 10) <= spetsAmount)}
															onChange={({target}) =>
															{
																this.setCustomerState({allocation_amount_initial: target.value.split(' ').join('')});
															}}
															highlightErrors={hasFormValidationErrors}
														/>
													</div>
												</StackLayout>

												<p style={{
													width: "600px",
													margin: "10px 0 5px 34px",
													lineHeight: "19px"
												}}>
													Ange när återstående insättningsbelopp ska överföras:
												</p>

												<div style={{paddingLeft: "34px", width: "100%", maxWidth: "640px"}}>
													<TextView
														name="allocation_amount_text"
														label={`${800 - customerState.allocation_amount_text.length} tecken kvar`}
														value={customerState.allocation_amount_text}
														maxLength={800}
														validation={value => value.length > 0}
														resize="vertical"
														onChange={({target}) => this.setCustomerState({allocation_amount_text: target.value})}
														fullWidth
														minHeight={150}
														highlightErrors={hasFormValidationErrors}
													/>
												</div>

											</React.Fragment>
										)}
									</div>
								</div>
							)}

							<Checkbox
								style={{marginTop: "20px", paddingRight: "30px"}}
								type="checkbox"
								name="checkbox_product_guru"
								label="Fair Flexibel"
								value={customerState.checkbox_product_guru}
								checked={customerState.checkbox_product_guru}
								disabled={customerState.guru_disabled}
								onChange={checked => this.setCustomerState({checkbox_product_guru: checked})}
								highlightErrors={hasFormValidationErrors}
								groupValid={
									customerState.checkbox_product_spets ||
									customerState.checkbox_product_guru ||
									customerState.checkbox_product_svan ||
									customerState.checkbox_product_advice
								}
							/>

							{customerState.checkbox_product_guru && (
								<div className="product">
									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Belopp att investera</p>
											<NumberField
												name="allocation_amount_guru"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_amount_guru || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_amount_guru: target.value.split(' ').join('')});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
											<Select
												name="radio_allocation_perc_guru"
												label="Ange i procent"
												selectedIndex={customerState.radio_allocation_perc_guruIndex}
												options={[
													{label: "0-5%", value: "0-5"},
													{label: "6-10%", value: "6-10"},
													{label: "11-20%", value: "11-20"},
													{label: ">20%", value: ">20"}
												]}
												onChange={(checked, radio_allocation_perc_guru) => this.setCustomerState({radio_allocation_perc_guru})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Risk enligt "Information om riskklasser"</p>
											<strong style={{fontSize: "16px"}}>3</strong>
											<input type="hidden" name="radio_allocation_risk_guru" value="3"/>
										</div>
										<div>
											<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
											<Select
												name="checkbox_accounttype_guru"
												label="Ange"
												selectedIndex={customerState.checkbox_accounttype_guruIndex}
												options={accountTypeOptions}
												onChange={(checked, checkbox_accounttype_guru) => this.setCustomerState({checkbox_accounttype_guru})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Placeringshorisont</p>
											<strong>Minst 3 år</strong>
										</div>
									</StackLayout>

									{customerState.radio_guru_fixed_fee === "yes" && (
										<React.Fragment>
											<input type="hidden" name="allocation_fee_guru" value=""/>
											<input type="hidden" name="allocation_fee_guru_revenueshare" value=""/>
											<input type="hidden" name="allocation_fee_guru_rebate" value=""/>
											<input type="hidden" name="radio_allocation_fee_guru" value="fixed"/>
											<input type="hidden" name="account_advice_partnerfee_guru" value=""/>
										</React.Fragment>
									)}

									{customerState.radio_guru_fixed_fee === "no" && (
										<React.Fragment>

											<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
												<div>
													<p className="fieldLabel">
														Vinstdelning
													</p>
													<div style={{flexDirection: "row"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.allocation_fee_guru_revenueshare || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({allocation_fee_guru_revenueshare: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<p className="fieldLabel" style={{marginTop: "10px"}}>
														Förvaltningsrabatt
													</p>
													<div style={{flexDirection: "row"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.allocation_fee_guru_rebate || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({allocation_fee_guru_rebate: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<input type="hidden" name="allocation_fee_guru"
														   value={customerState.allocation_fee_guru_revenueshare
															   ? customerState.allocation_fee_guru_revenueshare
															   : ""}/>

													<input type="hidden" name="allocation_fee_guru_revenueshare"
														   value={customerState.allocation_fee_guru_revenueshare
															   ? customerState.allocation_fee_guru_revenueshare
															   : ""}/>

													<input type="hidden" name="allocation_fee_guru_rebate"
														   value={customerState.allocation_fee_guru_rebate
															   ? customerState.allocation_fee_guru_rebate
															   : ""}/>

													<input type="hidden" name="radio_allocation_fee_guru"
														   value={customerState.allocation_fee_guru_revenueshare && customerState.allocation_fee_guru_revenueshare === "33"
															   ? "standard"
															   : "reduced"}/>
												</div>

												<div>
													<p className="fieldLabel">
														Ersättning till rådgivaren <br/>(i % av förvaltningsavgift)
													</p>
													<div style={{flexDirection: "row"}}>
														<SimpleTextField
															type="number"
															placeholder="Ange i %"
															value={customerState.account_advice_partnerfee_guru || ""}
															textAlign="left"
															validation={value => value.length > 0}
															style={{width: "100px", marginRight: "5px"}}
															onChange={({target}) => this.setCustomerState({account_advice_partnerfee_guru: target.value})}
															highlightErrors={hasFormValidationErrors}
														/>
														%
													</div>

													<input type="hidden" name="account_advice_partnerfee_guru"
														   value={customerState.account_advice_partnerfee_guru ? customerState.account_advice_partnerfee_guru : ""}/>
												</div>
											</StackLayout>

										</React.Fragment>
									)}

									<div style={{alignItems: "flex-start", width: "100%", margin: "10px 0 0 0"}}>
										<Checkbox
											type="checkbox"
											name="checkbox_special_terms_agreed"
											value={customerState.checkbox_special_terms_agreed === "yes" ? "yes" : "no"}
											label={"Överrenskommelse om avvikande villkor"}
											checked={customerState.checkbox_special_terms_agreed === "yes"}
											onChange={checked => this.setCustomerState({checkbox_special_terms_agreed: checked ? "yes" : "no"})}
										/>
									</div>
								</div>
							)}

							<Checkbox
								style={{marginTop: "20px", paddingRight: "30px"}}
								type="checkbox"
								name="checkbox_product_svan"
								label="Strategi Svart Svan"
								value={customerState.checkbox_product_svan}
								checked={customerState.checkbox_product_svan}
								onChange={checked => this.setCustomerState({checkbox_product_svan: checked})}
								highlightErrors={hasFormValidationErrors}
								groupValid={
									customerState.checkbox_product_spets ||
									customerState.checkbox_product_guru ||
									customerState.checkbox_product_svan ||
									customerState.checkbox_product_advice
								}
							/>

							{customerState.checkbox_product_svan && (
								<div className="product">

									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Exponeringsbelopp</p>
											<NumberField
												name="allocation_svan_exponeringsbelopp"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_svan_exponeringsbelopp || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_svan_exponeringsbelopp: target.value.split(' ').join('')});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>

									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Belopp att investera</p>
											<NumberField
												name="allocation_amount_svan"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_amount_svan || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_amount_svan: target.value.split(' ').join('')});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
											<Select
												name="radio_allocation_perc_svan"
												label="Ange i procent"
												selectedIndex={customerState.radio_allocation_perc_svanIndex}
												options={[
													{label: "0-5%", value: "0-5"},
													{label: "6-10%", value: "6-10"},
													{label: "11-20%", value: "11-20"},
													{label: ">20%", value: ">20"}
												]}
												onChange={(checked, radio_allocation_perc_svan) => this.setCustomerState({radio_allocation_perc_svan})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Risk enligt "Information om riskklasser"</p>
											<strong style={{fontSize: "16px"}}>7</strong>
											<input type="hidden" name="radio_allocation_risk_svan" value="7"/>
										</div>
										<div>
											<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
											<Select
												name="checkbox_accounttype_svan"
												label="Ange"
												selectedIndex={customerState.checkbox_accounttype_svanIndex}
												options={accountTypeOptions}
												onChange={(checked, checkbox_accounttype_svan) => this.setCustomerState({checkbox_accounttype_svan})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Placeringshorisont</p>
											<NumberField
												name="allocation_horizon_svan"
												label="Ange i år"
												unit=" år"
												value={customerState.allocation_horizon_svan || ""}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_horizon_svan: target.value});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>
									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">
												Vinstdelning
											</p>
											<div style={{flexDirection: "row", padding: "3px"}}>
												1/3 med evigt highwatermark
											</div>
											<input type="hidden" name="allocation_fee_svan" value="1/3"/>
										</div>

										<div>
											<p className="fieldLabel">
												Ersättning till rådgivaren<br/>(i % av förvaltningsavgiften)
											</p>

											<div style={{flexDirection: "row"}}>
												<SimpleTextField
													type="number"
													placeholder="Ange i %"
													value={customerState.account_advice_partnerfee_svan || ""}
													textAlign="left"
													validation={value => value.length > 0}
													style={{width: "100px", marginRight: "5px"}}
													onChange={({target}) => this.setCustomerState({account_advice_partnerfee_svan: target.value})}
													highlightErrors={hasFormValidationErrors}
												/>
												%
											</div>

											<input type="hidden" name="account_advice_partnerfee_svan"
												   value={customerState.account_advice_partnerfee_svan ? customerState.account_advice_partnerfee_svan : ""}/>

										</div>
									</StackLayout>
								</div>
							)}

							{customerState.checkbox_special_terms_agreed === "yes" && (
								<React.Fragment>
									<div className="h-line spacing"/>
									<h3 style={{paddingBottom: "10px"}}>Fyll i avvikande villkor</h3>

									<TextView
										name="discagreement_other"
										label={`${100 - customerState.discagreement_other.length} tecken kvar`}
										value={customerState.discagreement_other}
										maxLength={100}
										validation={value => value.length > 0}
										resize="vertical"
										onChange={({target}) => this.setCustomerState({discagreement_other: target.value})}
										fullWidth
										minHeight={50}
										highlightErrors={hasFormValidationErrors}
									/>
								</React.Fragment>
							)}

							<div className="h-line spacing"/>
							<h3>Investeringsrådgivning</h3>

							{!customerState.investmentAdviceEnabled && (
								<p style={{fontStyle: "italic"}}>
									Kundens valda risknivå för detta placeringstillfälle är {customerState.radio_advice_riskpropensity}.<br />
									Investeringsrådgivning är enbart tillgängligt vid risknivåerna 6 och 7.
								</p>
							)}

							<Checkbox
								style={{marginTop: "20px"}}
								type="checkbox"
								name="checkbox_product_advice"
								label="Inkludera avsnitt om investeringsrådgivning"
								value={customerState.checkbox_product_advice}
								checked={customerState.investmentAdviceEnabled && customerState.checkbox_product_advice}
								disabled={!customerState.investmentAdviceEnabled}
								onChange={checked => this.setCustomerState({checkbox_product_advice: checked})}
								highlightErrors={customerState.investmentAdviceEnabled && hasFormValidationErrors}
								groupValid={
									customerState.checkbox_product_spets ||
									customerState.checkbox_product_guru ||
									customerState.checkbox_product_svan ||
									customerState.checkbox_product_advice
								}
							/>

							{customerState.checkbox_product_advice && (
								<div className="product">
									<Checkbox
										style={{marginTop: "20px", alignSelf: "flex-start", marginBottom: "20px"}}
										type="checkbox"
										name="checkbox_allocation_advice_understand"
										label="Den rådgivning som du erhåller är icke-oberoende. Min rådgivare har förklarat för mig vad det innebär."
										value="Ja"
										checked={customerState.checkbox_allocation_advice_understand === "Ja"}
										onChange={checked => this.setCustomerState({checkbox_allocation_advice_understand: checked ? "Ja" : "Nej"})}
										highlightErrors={hasFormValidationErrors}
									/>

									<Checkbox
										style={{marginTop: "20px", alignSelf: "flex-start", marginBottom: "20px"}}
										type="checkbox"
										name="checkbox_allocation_advice_is_fund"
										label="Rådgivningen gäller investering i fond."
										value="Ja"
										checked={customerState.checkbox_allocation_advice_is_fund === "Ja"}
										onChange={checked => this.setCustomerState({checkbox_allocation_advice_is_fund: checked ? "Ja" : "Nej"})}
									/>

									<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Produkt*</p>
											<Select
												name="allocation_product_advice_select"
												label="Välj"
												selectedIndex={this.investmentAdviceProducts.map(c => c.value).indexOf(customerState.allocation_product_advice_select)}
												options={this.investmentAdviceProducts}
												onChange={(option) => this.setCustomerState({allocation_product_advice_select: option.value})}
												highlightErrors={hasFormValidationErrors}
											/>

											{customerState.allocation_product_advice_select === "Annan" && (
												<React.Fragment>
													<TextField
														name="allocation_product_advice"
														type="text"
														label="Ange namn"
														validation={value => value.length > 0}
														value={customerState.allocation_product_advice}
														onChange={({target}) => this.setCustomerState({allocation_product_advice: target.value})}
														highlightErrors={hasFormValidationErrors}
														style={{marginTop: "5px"}}
													/>

													<p style={{padding: "5px 10px", fontSize: "12px"}}>
													* Notera att du kommer behöva bifoga produktens faktablad senare i
													rådgivningsflödet.
													</p>
												</React.Fragment>
											)}

											{customerState.allocation_product_advice_select !== "Annan" && (
												<input type={"hidden"} name={"allocation_product_advice"} value={customerState.allocation_product_advice_select} />
											)}
										</div>
									</StackLayout>

									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">Belopp att investera</p>
											<NumberField
												name="allocation_amount_advice"
												label="Ange i SEK"
												unit=" SEK"
												value={customerState.allocation_amount_advice || ""}
												showSeparators={true}
												onChange={({target}) =>
												{
													let amountToInvest = target.value.split(' ').join('');
													let courtageSek = "";
													let partnerFee = "";
													if (amountToInvest > 0 && customerState.allocation_fee_advice > 0)
													{
														courtageSek = Math.ceil(amountToInvest * (customerState.allocation_fee_advice / 100));

														if (customerState.account_advice_partnerfee_advice > 0)
														{
															partnerFee = Math.ceil(courtageSek * (customerState.account_advice_partnerfee_advice / 100));
														}
													}

													this.setCustomerState({
														allocation_amount_advice: amountToInvest,
														allocation_perffee_advice: courtageSek,
														account_advice_partnerfeeinsek_advice: partnerFee
													});
												}}
												highlightErrors={hasFormValidationErrors}
											/>

											<input type={"hidden"} name={"allocation_amount_advice_plain"}
											       value={localeNumber((parseInt(customerState.allocation_amount_advice.replace(" SEK", "").split(' ').join('')) - customerState.allocation_perffee_advice), 0)} />
										</div>
										<div>
											<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
											<Select
												name="radio_allocation_perc_advice"
												label="Ange i procent"
												selectedIndex={customerState.radio_allocation_perc_adviceIndex}
												options={[
													{label: "0-5%", value: "0-5"},
													{label: "6-10%", value: "6-10"},
													{label: "11-20%", value: "11-20"},
													{label: ">20%", value: ">20"}
												]}
												onChange={(checked, radio_allocation_perc_advice) => this.setCustomerState({radio_allocation_perc_advice})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Risk enligt "Information om riskklasser"</p>
											<Select
												name="radio_allocation_risk_advice"
												label="Ange risknivå"
												selectedIndex={customerState.radio_allocation_risk_adviceIndex}
												options={[
													{label: "1", value: "1"},
													{label: "2", value: "2"},
													{label: "3", value: "3"},
													{label: "4", value: "4"},
													{label: "5", value: "5"},
													{label: "6", value: "6"},
													{label: "7", value: "7"}
												]}
												onChange={(checked, radio_allocation_risk_advice) => this.setCustomerState({radio_allocation_risk_advice})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
											<Select
												name="checkbox_accounttype_advice"
												label="Ange"
												selectedIndex={customerState.checkbox_accounttype_adviceIndex}
												options={accountTypeOptions}
												onChange={(checked, checkbox_accounttype_advice) => this.setCustomerState({checkbox_accounttype_advice})}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<p className="fieldLabel">Placeringshorisont</p>
											<NumberField
												name="allocation_horizon_advice"
												label="Ange i år"
												unit=" år"
												value={customerState.allocation_horizon_advice || ""}
												onChange={({target}) =>
												{
													this.setCustomerState({allocation_horizon_advice: target.value});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>

									<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
										<div>
											<p className="fieldLabel">
												Courtage
											</p>
											<div style={{flexDirection: "row"}}>
												<SimpleTextField
													type="number"
													placeholder="Ange"
													name="allocation_fee_advice"
													value={customerState.allocation_fee_advice || ""}
													textAlign="left"
													validation={value => value.length > 0}
													style={{width: "100px", marginRight: "5px"}}
													onChange={({target}) =>
													{
														let courtageSek = "";
														let partnerFee = "";
														let investedAmount = customerState.allocation_amount_advice ? customerState.allocation_amount_advice.replace(" SEK", "").split(' ').join('') : 0;

														if (investedAmount > 0 && target.value > 0)
														{
															courtageSek = Math.ceil(investedAmount * (target.value / 100));
															if (customerState.account_advice_partnerfee_advice > 0)
															{
																partnerFee = Math.ceil(courtageSek * (customerState.account_advice_partnerfee_advice / 100));
															}
														}

														this.setCustomerState({
															allocation_fee_advice: target.value,
															allocation_perffee_advice: courtageSek,
															account_advice_partnerfeeinsek_advice: partnerFee
														});
													}}
													highlightErrors={hasFormValidationErrors}
												/>
												%
											</div>

											{customerState.allocation_perffee_advice > 0 && (
												<p className="fieldLabel"
												   style={{marginTop: "3px", fontWeight: "normal", fontSize: "11px"}}>
													= {localeNumber(customerState.allocation_perffee_advice, 0)} SEK
												</p>
											)}

											<input type={"hidden"} name={"allocation_perffee_advice"}
												   value={customerState.allocation_perffee_advice}/>
										</div>

										<div>
											<p className="fieldLabel">
												Ersättning till rådgivaren (betalas av Fair och utgör ingen extra avgift
												utöver "Ersättning till Fair" som anges i produktbladet)
											</p>

											<div style={{flexDirection: "row"}}>
												<SimpleTextField
													type="number"
													placeholder="Ange i %"
													value={customerState.account_advice_partnerfee_advice || ""}
													textAlign="left"
													validation={value => value.length > 0}
													style={{width: "100px", marginRight: "5px"}}
													onChange={({target}) =>
													{
														let courtageSek = customerState.allocation_perffee_advice ? customerState.allocation_perffee_advice : 0;
														let investedAmount = customerState.allocation_amount_advice ? customerState.allocation_amount_advice.replace(" SEK", "").split(' ').join('') : 0;
														let partnerFee = "";
														if (investedAmount > 0 && courtageSek > 0 && target.value > 0)
														{
															partnerFee = Math.ceil(courtageSek * (target.value / 100));
														}
														this.setCustomerState({
															account_advice_partnerfee_advice: target.value,
															account_advice_partnerfeeinsek_advice: partnerFee
														});
													}}
													highlightErrors={hasFormValidationErrors}
												/>
												%
											</div>

											{customerState.account_advice_partnerfeeinsek_advice > 0 && (
												<p className="fieldLabel"
												   style={{marginTop: "3px", fontWeight: "normal", fontSize: "11px"}}>
													= {localeNumber(customerState.account_advice_partnerfeeinsek_advice, 0)} SEK
												</p>
											)}

											<input type="hidden" name="account_advice_partnerfee_advice"
												   value={customerState.account_advice_partnerfee_advice ? customerState.account_advice_partnerfee_advice : ""}/>
										</div>
									</StackLayout>

									<input type="hidden" name="account_advice_partnerfeeinsek_advice"
										   value={customerState.account_advice_partnerfeeinsek_advice}/>
								</div>
							)}

							<div className="h-line spacing"/>

							<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
								<div className="yes-no">
									<Checkbox
										style={{
											padding: "15px",
											background: "#FFF6F6",
											borderRadius: "6px"
										}}
										className="no"
										type="radio"
										name="checkbox_advice_customer"
										label="Kunden har tagit del av det diskretionära avtalet/avtalen men förstår inte hur förvaltningen/förvaltningarna fungerar eller vilka risker som den/de är förknippade med. Placeringen avråds av rådgivaren. "
										value="dontunderstand"
										checked={customerState.checkbox_advice_customer === "dontunderstand"}
										onChange={checked => this.setCustomerState({checkbox_advice_customer: checked ? "dontunderstand" : "understand"})}
										highlightErrors={hasFormValidationErrors && customerState.checkbox_advice_customer.length < 1}
									/>
								</div>

								<div className="yes-no">
									<Checkbox
										style={{
											padding: "15px",
											background: "#F3FDF5",
											borderRadius: "6px"
										}}
										className="yes"
										type="radio"
										name="checkbox_advice_customer"
										label="Kunden har tagit del av det diskretionära avtalet/avtalen och intygar att den förstår hur förvaltningen/förvaltningarna fungerar och vilka risker som den/de är förknippade med."
										value="understand"
										checked={customerState.checkbox_advice_customer === "understand"}
										onChange={checked => this.setCustomerState({checkbox_advice_customer: checked ? "understand" : "dontunderstand"})}
										highlightErrors={hasFormValidationErrors && customerState.checkbox_advice_customer.length < 1}
									/>
								</div>

								<input type="hidden" name="checkbox_advice_recommendation2"
									   value={customerState.checkbox_advice_customer}/>
							</StackLayout>

							<p style={{marginTop: "0"}}>
								Kundens kunskap eller erfarenhet gällande de i förvaltningen ingående instrumenten
							</p>
							<Select
								name="radio_advice_knowledge"
								label="Ange lägsta kunskapsnivå"
								selectedIndex={customerState.radio_advice_knowledgeIndex}
								options={[
									{label: "Ingen", value: "ingen"},
									{label: "Någon", value: "nagon"},
									{label: "Medel", value: "medel"},
									{label: "Hög", value: "hog"}
								]}
								onChange={(checked, radio_advice_knowledge) => this.setCustomerState({radio_advice_knowledge})}
								highlightErrors={hasFormValidationErrors}
							/>

							{customerState.checkbox_advice_customer && (
								<React.Fragment>
									{customerState.checkbox_advice_customer === "dontunderstand" && (
										<TextView
											style={{marginTop: "20px"}}
											name="advice_customer_suitability"
											label={`Placeringen avråds då kunden inte förstått förvaltningen eller dess risker. Motivering lyder: (${1800 - customerState.advice_customer_suitability.length} tecken kvar)`}
											value={customerState.advice_customer_suitability}
											maxLength={1800}
											validation={value => value.length > 0}
											resize="vertical"
											onChange={({target}) => this.setCustomerState({advice_customer_suitability: target.value})}
											fullWidth
											minHeight={250}
											highlightErrors={hasFormValidationErrors}
										/>
									)}

									{customerState.checkbox_advice_customer === "understand" && (
										<TextView
											style={{marginTop: "20px"}}
											name="advice_customer_suitability"
											label={`Motivering till vald förvaltningstjänst/er: (${2000 - customerState.advice_customer_suitability.length} tecken kvar)`}
											value={customerState.advice_customer_suitability}
											maxLength={2000}
											validation={value => value.length > 0}
											resize="vertical"
											onChange={({target}) => this.setCustomerState({advice_customer_suitability: target.value})}
											fullWidth
											minHeight={200}
											highlightErrors={hasFormValidationErrors}
										/>
									)}
								</React.Fragment>
							)}


							<div className="h-line spacing"/>

							<h4>Föranmält konto</h4>

							<Checkbox
								style={{marginTop: "20px"}}
								type="checkbox"
								name="checkbox_skip_account"
								label="Försäkring - Kontonummer ej tillämpligt"
								value={customerState.checkbox_skip_account}
								checked={customerState.checkbox_skip_account}
								onChange={checked => this.setCustomerState({checkbox_skip_account: checked})}
							/>

							<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
								<div>
									<TextField
										name="customer_account_bank_owner"
										label="Namn på depå-/kontohavare"
										value={!customerState.checkbox_skip_account ? customerState.customer_account_bank_owner : ""}
										validation={value => value.length > 0}
										disabled={customerState.checkbox_skip_account}
										onChange={({target}) => this.setCustomerState({customer_account_bank_owner: target.value})}
										highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
									/>
								</div>
								<div>
									<Select
										name="customer_account_bank"
										label="Bank"
										selectedIndex={customerState.selectedBankIndex}
										options={availableBankOptions}
										disabled={customerState.checkbox_skip_account}
										onChange={this._onBankChange}
										highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
									/>

									{customerState.customer_account_bank === '-' && (
										<React.Fragment>
											<TextField
												name="customer_account_bank_custom"
												label="Namn på bank"
												style={{marginTop: "5px"}}
												value={!customerState.checkbox_skip_account ? customerState.customer_account_bank_custom : ""}
												validation={value => value.length > 0}
												disabled={customerState.checkbox_skip_account}
												onChange={({target}) => this.setCustomerState({customer_account_bank_custom: target.value})}
												highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
											/>
											<input type="hidden" name="customer_account_bank"
												   value={customerState.customer_account_bank_custom}/>
										</React.Fragment>
									)}

									{customerState.customer_account_bank !== '-' && (
										<input type="hidden" name="customer_account_bank_custom" value=""/>
									)}

									{customerState.checkbox_skip_account && (
										<input type="hidden" name="customer_account_bank" value=""/>
									)}
								</div>
							</StackLayout>

							<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
								<div>
									<NumberField
										name="customer_account_bank_clearingno"
										label="Clearingnummer"
										delimiter1={""}
										disabled={customerState.checkbox_skip_account}
										value={!customerState.checkbox_skip_account ? (customerState.customer_account_bank_clearingno || "") : ""}
										validation={this._validateClearingNumber}
										onChange={this._onAccountClearingChange}
										highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
									/>

									{customerState.customer_account_bank === 'Swedbank' && (
										<p style={{padding: "0 0 0 7px", fontSize: "12px"}}>
											Det förekommer att Swedbank har en femte siffra i clearingnumret. Vänligen
											ange
											enbart de fyra första innan bindestrecket. Exempel: 8214-9 ska anges som
											8214.
										</p>
									)}
								</div>
								<div>
									<TextField
										name="customer_account_bank_no"
										label="Depå-/kontonummer"
										value={!customerState.checkbox_skip_account ? (customerState.customer_account_bank_no || "") : ""}
										disabled={customerState.checkbox_skip_account}
										type={"number"}
										validation={this._numberValidation}
										onChange={this._onAccountNumberChange}
										highlightErrors={hasFormValidationErrors && !customerState.checkbox_skip_account}
									/>
								</div>
							</StackLayout>

							<Checkbox
								style={{marginTop: "-10px"}}
								type="checkbox"
								name="checkbox_override_validation"
								label="Tillåt konto- och clearingnummer som inte kan valideras"
								value={customerState.checkbox_override_validation}
								checked={customerState.checkbox_override_validation}
								disabled={customerState.checkbox_skip_account}
								onChange={checked => this.setCustomerState({checkbox_override_validation: checked})}
							/>

							{isIskTypeSelected && (
								<input type="hidden" name="checkbox_account_isk" value="Ja"/>
							)}

							{!isIskTypeSelected && (
								<input type="hidden" name="checkbox_account_isk" value="Nej"/>
							)}

							<input type="hidden" name="allocation_amount_total" value={totalAmountInvested + " SEK"}/>
						</div>
					)}
				</ContentBox>

				<input type="hidden" name={"allocation_amount_first"} value={allocationFields.allocation_amount_first}/>
				<input type="hidden" name={"allocation_product_first"}
					   value={allocationFields.allocation_product_first}/>
				<input type="hidden" name={"allocation_product_bilaga_first"}
					   value={allocationFields.allocation_product_bilaga_first}/>
				<input type="hidden" name={"radio_allocation_fee_first"}
					   value={allocationFields.radio_allocation_fee_first}/>
				<input type="hidden" name={"allocation_fee_revshare_first"}
					   value={allocationFields.allocation_fee_revshare_first}/>
				<input type="hidden" name={"allocation_fee_rebate_first"}
					   value={allocationFields.allocation_fee_rebate_first}/>

				<input type="hidden" name={"allocation_amount_second"}
					   value={allocationFields.allocation_amount_second}/>
				<input type="hidden" name={"allocation_product_second"}
					   value={allocationFields.allocation_product_second}/>
				<input type="hidden" name={"allocation_product_bilaga_second"}
					   value={allocationFields.allocation_product_bilaga_second}/>
				<input type="hidden" name={"radio_allocation_fee_second"}
					   value={allocationFields.radio_allocation_fee_second}/>
				<input type="hidden" name={"allocation_fee_revshare_second"}
					   value={allocationFields.allocation_fee_revshare_second}/>
				<input type="hidden" name={"allocation_fee_rebate_second"}
					   value={allocationFields.allocation_fee_rebate_second}/>
			</div>
		);
	}

	_onBankChange = (option) =>
	{
		this.setCustomerState(
			{
				customer_account_bank: option.value
			}
		);
	};

	_validateClearingNumber = (value) =>
	{
		if (this.state.customerState.customer_account_bank === '-')
		{
			return true;
		}
		return Validator.validateClearingNumber(value, this.state.customerState.customer_account_bank, this.props.availableBanks);
	};

	_onAccountClearingChange = ({target}) =>
	{
		if (isNaN(target.value))
		{
			return;
		}

		this.setCustomerState(
			{
				customer_account_bank_clearingno: target.value
			}
		);
	};

	_numberValidation = (value) =>
	{
		if (this.state.customerState.customer_account_bank === '-')
		{
			return true;
		}

		if (value.length < 5 || isNaN(value))
		{
			return false;
		}

		/**
		 * For the banks where we know the validation rules, check if the number should be validated.
		 */
		let shouldValidateAccountNumber = false;
		const availableBanks = this.props.availableBanks;
		for (const bankName in availableBanks)
		{
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName))
			{
				continue;
			}

			if (this.state.customerState.customer_account_bank === bankName)
			{
				const bankInfo = availableBanks[bankName];
				if (bankInfo.length > 0)
				{
					if (bankInfo[0]['validationEnabled'] === "1")
					{
						shouldValidateAccountNumber = true;
					}
				}
				break;
			}
		}

		if (!shouldValidateAccountNumber)
		{
			return true;
		}

		let bankNoToValidate = this.state.customerState.customer_account_bank_clearingno + ", " + value;
		let kontonummer = window.kontonummer(bankNoToValidate);
		return kontonummer !== false;
	};

	_AddonPlacementFulfillment = (fulfilled) =>
	{
		this.setCustomerState({addon_placement_fulfilled: fulfilled});
	};

	_onAccountNumberChange = ({target}) => !isNaN(target.value) ? this.setCustomerState({customer_account_bank_no: target.value}) : undefined;

	_optionsValidation = (value) => ((value && value.length > 0) || false);
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;

	let selectedBankIndex = -1;

	if (newState.customerState.customer_account_bank && newState.customerState.customer_account_bank.length > 0)
	{
		let bankFoundInList = false;
		for (const bankName in this.props.availableBanks)
		{
			if (bankName === 'length' || !this.props.availableBanks.hasOwnProperty(bankName))
			{
				continue;
			}
			selectedBankIndex++;
			if (bankName === newState.customerState.customer_account_bank)
			{
				bankFoundInList = true;
				break;
			}
		}

		/**
		 * No bank found => Övrig bank
		 */
		if (!bankFoundInList)
		{
			selectedBankIndex++;
			newState.customerState.customer_account_bank_custom = newState.customerState.customer_account_bank;
			newState.customerState.customer_account_bank = "-";
		}
	}

	newState.customerState.selectedBankIndex = selectedBankIndex;
	newState.availableBanks = this.props.availableBanks;

	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let newState = {
		isFulfilled: false,
		availableBanks: [],
		customerState: {
			force_no_placement: customer.getData("checkbox_advice_skip_placement") === "yes",
			checkbox_skip_account: customer.getData("checkbox_skip_account") === "true",
			checkbox_override_validation: customer.getData("checkbox_override_validation") === "true",
			checkbox_product_guru: false,
			checkbox_product_spets: false,
			checkbox_product_svan: false,
			checkbox_product_other: false,
			checkbox_product_advice: false,

			checkbox_allocation_advice_understand: customer.getData("checkbox_allocation_advice_understand") || "",
			checkbox_allocation_advice_is_fund: customer.getData("checkbox_allocation_advice_is_fund") || "",

			allocation_product_advice_select: customer.getData("allocation_product_advice_select") || "",
			allocation_product_advice: customer.getData("allocation_product_advice") || "",
			allocation_horizon_advice: customer.getData("allocation_horizon_advice") || "",
			allocation_amount_advice: customer.getData("allocation_amount_advice") || "",
			allocation_perffee_advice: customer.getData("allocation_perffee_advice") || "",
			allocation_fee_advice: customer.getData("allocation_fee_advice") || "",
			account_advice_partnerfee_advice: customer.getData("account_advice_partnerfee_advice") ? customer.getData("account_advice_partnerfee_advice").replace("%", "") : "",

			allocation_fee_guru_revenueshare: customer.getData("allocation_fee_guru") || "",
			allocation_fee_guru_rebate: customer.getData("allocation_fee_guru_rebate") || "",

			allocation_fee_spets_revenueshare: customer.getData("allocation_fee_spets") || "",
			allocation_fee_spets_rebate: customer.getData("allocation_fee_spets_rebate") || "",

			allocation_fee_other_revenueshare: customer.getData("allocation_fee_other_revenueshare") || "",
			allocation_fee_other_rebate: customer.getData("allocation_fee_other_rebate") || "",
			allocation_amount_text: customer.getData("allocation_amount_text") || "",

			allocation_fxhedge_spets: customer.getData("allocation_fxhedge_spets") || "no",

			checkbox_spets_mjukstart: customer.getData("checkbox_spets_mjukstart") || "no",

			checkbox_special_terms_agreed: (customer.getData("discagreement_other") && customer.getData("discagreement_other") !== '') ? "yes" : "no",
			discagreement_other: customer.getData("discagreement_other") || "",

			allocation_amount_guru: customer.getData("allocation_amount_guru") || "",
			allocation_amount_spets: customer.getData("allocation_amount_spets") || "",
			allocation_amount_other: customer.getData("allocation_amount_other") || "",
			allocation_amount_svan: customer.getData("allocation_amount_svan") || "",
			allocation_amount_initial: customer.getData("allocation_amount_initial") || "",

			allocation_svan_exponeringsbelopp: customer.getData("allocation_svan_exponeringsbelopp") || "",

			allocation_fee_guru: customer.getData("allocation_fee_guru") || "",
			allocation_fee_spets: customer.getData("allocation_fee_spets") || "",
			allocation_fee_other: customer.getData("allocation_fee_other") || "",
			allocation_separate_deposit: customer.getData("allocation_separate_deposit") || "",

			account_advice_partnerfee_guru: customer.getData("account_advice_partnerfee_guru") ? customer.getData("account_advice_partnerfee_guru").replace("%", "") : "",
			account_advice_partnerfee_spets: customer.getData("account_advice_partnerfee_spets") ? customer.getData("account_advice_partnerfee_spets").replace("%", "") : "",
			account_advice_partnerfee_svan: customer.getData("account_advice_partnerfee_svan") ? customer.getData("account_advice_partnerfee_svan").replace("%", "") : "",
			account_advice_partnerfee_other: customer.getData("account_advice_partnerfee_other") ? customer.getData("account_advice_partnerfee_other").replace("%", "") : "",
			account_advice_partnerfeeinsek_advice: customer.getData("account_advice_partnerfeeinsek_advice") ? customer.getData("account_advice_partnerfeeinsek_advice").replace(" SEK", "") : "",

			allocation_horizon_other: customer.getData("allocation_horizon_other") || "",
			allocation_horizon_svan: customer.getData("allocation_horizon_svan") || "",

			radio_advice_knowledge: customer.getData("radio_advice_knowledge") || "",
			radio_advice_knowledgeIndex:
				customer.getData("radio_advice_knowledge") === "ingen" ? 0 :
					customer.getData("radio_advice_knowledge") === "nagon" ? 1 :
						customer.getData("radio_advice_knowledge") === "medel" ? 2 :
							customer.getData("radio_advice_knowledge") === "hog" ? 3 :
								-1,

			radio_allocation_perc_guru: customer.getData("radio_allocation_perc_guru") || "",
			radio_allocation_perc_guruIndex:
				customer.getData("radio_allocation_perc_guru") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_guru") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_guru") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_guru") === ">20" ? 3 :
								-1,

			radio_allocation_perc_spets: customer.getData("radio_allocation_perc_spets") || "",
			radio_allocation_perc_spetsIndex:
				customer.getData("radio_allocation_perc_spets") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_spets") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_spets") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_spets") === ">20" ? 3 :
								-1,

			radio_allocation_perc_other: customer.getData("radio_allocation_perc_other") || "",
			radio_allocation_perc_otherIndex:
				customer.getData("radio_allocation_perc_other") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_other") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_other") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_other") === ">20" ? 3 :
								-1,

			radio_allocation_perc_svan: customer.getData("radio_allocation_perc_svan") || "",
			radio_allocation_perc_svanIndex:
				customer.getData("radio_allocation_perc_svan") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_svan") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_svan") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_svan") === ">20" ? 3 :
								-1,

			radio_allocation_perc_advice: customer.getData("radio_allocation_perc_advice") || "",
			radio_allocation_perc_adviceIndex:
				customer.getData("radio_allocation_perc_advice") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_advice") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_advice") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_advice") === ">20" ? 3 :
								-1,

			radio_allocation_risk_guru: "3",
			radio_allocation_risk_spets: "4",
			radio_allocation_risk_other: "6",
			radio_allocation_risk_svan: "7",

			radio_allocation_risk_advice: customer.getData("radio_allocation_risk_advice") || "",
			radio_allocation_risk_adviceIndex:
				customer.getData("radio_allocation_risk_advice") === "1" ? 0 :
					customer.getData("radio_allocation_risk_advice") === "2" ? 1 :
						customer.getData("radio_allocation_risk_advice") === "3" ? 2 :
							customer.getData("radio_allocation_risk_advice") === "4" ? 3 :
								customer.getData("radio_allocation_risk_advice") === "5" ? 4 :
									customer.getData("radio_allocation_risk_advice") === "6" ? 5 :
										customer.getData("radio_allocation_risk_advice") === "7" ? 6 :
											-1,

			checkbox_accounttype_advice: customer.getData("checkbox_accounttype_advice") || "",
			checkbox_accounttype_adviceIndex: -1,

			checkbox_accounttype_guru: customer.getData("checkbox_accounttype_guru") || "",
			checkbox_accounttype_guruIndex: -1,

			checkbox_accounttype_spets: customer.getData("checkbox_accounttype_spets") || "",
			checkbox_accounttype_spetsIndex: -1,

			checkbox_accounttype_other: customer.getData("checkbox_accounttype_other") || "",
			checkbox_accounttype_otherIndex: -1,

			checkbox_accounttype_svan: customer.getData("checkbox_accounttype_svan") || "",
			checkbox_accounttype_svanIndex: -1,

			checkbox_advice_customer: customer.getData("checkbox_advice_customer") || "",
			advice_customer_suitability: customer.getData("advice_customer_suitability") || "",
			allocation_type_other: "Ädelmetallförvaltning",

			customer_account_bank_owner: customer.getData("customer_account_bank_owner") || "",
			customer_account_bank: customer.getData("customer_account_bank") || "",
			customer_account_bank_custom: "",
			customer_account_bank_clearingno: customer.getData("customer_account_bank_clearingno") || "",
			customer_account_bank_no: customer.getData("customer_account_bank_no") || "",

			checkbox_customer_is_audience: customer.getData("checkbox_customer_is_audience") || "",

			radio_advice_riskpropensity: customer.getData("radio_advice_riskpropensity") || "",

			radio_product_selection_mode: "",
			change_fee_model_fulfilled: "",
			addon_placement_fulfilled: "",

			radio_spets_fixed_fee: "no",
			radio_guru_fixed_fee: "no"
		},
	};

	if (newState.customerState.allocation_amount_guru.length > 0)
	{
		if (customer.getData("customer_guru_depa"))
		{
			newState.customerState.radio_product_selection_mode = "addonchange";
		}
		else
		{
			newState.customerState.radio_product_selection_mode = "new";
			newState.customerState.checkbox_product_guru = true;

			if (((customer.getData("allocation_product_first") === "Guru" ||
									customer.getData("allocation_product_first") === "Flexibel")
						&& customer.getData("radio_allocation_fee_first") === "fixed")
					||
				((customer.getData("allocation_product_second") === "Guru" ||
								customer.getData("allocation_product_second") === "Flexibel")
						&& customer.getData("radio_allocation_fee_second") === "fixed"))
			{
				newState.customerState.radio_guru_fixed_fee = "no"; // Set to yes if we want to enable this functionality again
			}
			else
			{
				newState.customerState.radio_guru_fixed_fee = "no";
			}
		}
	}

	if (newState.customerState.allocation_amount_svan.length > 0)
	{
		if (customer.getData("customer_guru_svan"))
		{
			newState.customerState.radio_product_selection_mode = "addonchange";
		}
		else
		{
			newState.customerState.radio_product_selection_mode = "new";
			newState.customerState.checkbox_product_svan = true;
		}
	}

	if (newState.customerState.allocation_amount_spets.length > 0)
	{
		if (customer.getData("customer_spets_depa"))
		{
			newState.customerState.radio_product_selection_mode = "addonchange";
		}
		else
		{
			newState.customerState.radio_product_selection_mode = "new";
			newState.customerState.checkbox_product_spets = true;

			if (((customer.getData("allocation_product_first") === "Spets" ||
									customer.getData("allocation_product_first") === "Global")
							&& customer.getData("radio_allocation_fee_first") === "fixed")
					||
				((customer.getData("allocation_product_second") === "Spets" ||
								customer.getData("allocation_product_second") === "Global")
						&& customer.getData("radio_allocation_fee_second") === "fixed")
			)
			{
				newState.customerState.radio_spets_fixed_fee = "no"; // Set to yes if we want to enable this functionality again
			}
			else
			{
				newState.customerState.radio_spets_fixed_fee = "no";
			}
		}
	}

	if (newState.customerState.allocation_amount_other.length > 0)
	{

		if (customer.getData("customer_other_depa"))
		{
			newState.customerState.radio_product_selection_mode = "addonchange";
		}
		else
		{
			newState.customerState.radio_product_selection_mode = "new";
			newState.customerState.checkbox_product_other = false;
		}
	}

	if (newState.customerState.allocation_amount_advice.length > 0)
	{
		newState.customerState.checkbox_product_advice = true;
		newState.customerState.radio_product_selection_mode = "new";
	}

	if (newState.customerState.radio_product_selection_mode === "" &&
		(
			customer.getData("radio_allocation_fee_guru") ||
			customer.getData("radio_allocation_fee_spets") ||
			customer.getData("radio_allocation_fee_other")
		))
	{
		newState.customerState.radio_product_selection_mode = "addonchange";
	}

	if (newState.customerState.radio_product_selection_mode === "")
	{
		newState.customerState.radio_product_selection_mode = customer.getData("radio_product_selection_mode") || "";
	}

	if (customer.getData("checkbox_advice_skip_placement") === "yes")
	{
		newState.customerState.radio_product_selection_mode = "nochange";
	}

	if (customer.isCompany)
	{
		newState.customerState.checkbox_accounttype_guruIndex =
			customer.getData("checkbox_accounttype_guru") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_guru") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_guru") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_guru") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_guru") === "kinsurance" ? 4 :
								-1;

		newState.customerState.checkbox_accounttype_spetsIndex =
			customer.getData("checkbox_accounttype_spets") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_spets") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_spets") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_spets") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_spets") === "kinsurance" ? 4 :
								-1;

		newState.customerState.checkbox_accounttype_otherIndex =
			customer.getData("checkbox_accounttype_other") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_other") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_other") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_other") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_other") === "kinsurance" ? 4 :
								-1;

		newState.customerState.checkbox_accounttype_svanIndex =
			customer.getData("checkbox_accounttype_svan") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_svan") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_svan") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_svan") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_svan") === "kinsurance" ? 4 :
								-1;

		newState.customerState.checkbox_accounttype_adviceIndex =
			customer.getData("checkbox_accounttype_advice") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_advice") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_advice") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_advice") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_advice") === "kinsurance" ? 4 :
								-1;
	}
	else
	{
		newState.customerState.checkbox_accounttype_guruIndex =
			customer.getData("checkbox_accounttype_guru") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_guru") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_guru") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_guru") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_guru") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_guru") === "kinsurance" ? 5 :
									-1;

		newState.customerState.checkbox_accounttype_spetsIndex =
			customer.getData("checkbox_accounttype_spets") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_spets") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_spets") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_spets") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_spets") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_spets") === "kinsurance" ? 5 :
									-1;

		newState.customerState.checkbox_accounttype_otherIndex =
			customer.getData("checkbox_accounttype_other") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_other") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_other") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_other") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_other") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_other") === "kinsurance" ? 5 :
									-1;

		newState.customerState.checkbox_accounttype_svanIndex =
			customer.getData("checkbox_accounttype_svan") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_svan") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_svan") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_svan") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_svan") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_svan") === "kinsurance" ? 5 :
									-1;

		newState.customerState.checkbox_accounttype_adviceIndex =
			customer.getData("checkbox_accounttype_advice") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_advice") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_advice") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_advice") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_advice") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_advice") === "kinsurance" ? 5 :
									-1;
	}

	let courtageSek = newState.customerState.allocation_perffee_advice ? newState.customerState.allocation_perffee_advice : 0;
	let partnerFeePct = newState.customerState.account_advice_partnerfee_advice ? newState.customerState.account_advice_partnerfee_advice : 0;
	if (courtageSek > 0 && partnerFeePct > 0)
	{
		newState.customerState.account_advice_partnerfeeinsek_advice = Math.ceil(courtageSek * (partnerFeePct / 100));
	}
	newState.customerState.guru_disabled = false;

	/**
	 * Investeringsrådgivning is only available if you have selected risk level 6 or 7
	 */
	if (newState.customerState.radio_advice_riskpropensity.length > 0 &&
		newState.customerState.radio_advice_riskpropensity !== "6"  &&
		newState.customerState.radio_advice_riskpropensity !== "7")
	{
		newState.customerState.checkbox_product_advice = false;
		newState.customerState.investmentAdviceEnabled = false;
	}
	else
	{
		newState.customerState.investmentAdviceEnabled = true;
	}

	return newState;
}

function _getFields()
{
	return [
		"allocation_amount_guru",
		"allocation_amount_spets",
		"allocation_amount_svan",
		"allocation_amount_other",
		"allocation_amount_initial",
		"allocation_svan_exponeringsbelopp",
		"allocation_separate_deposit",

		"allocation_fee_guru",
		"allocation_fee_spets",
		"allocation_fee_svan",
		"allocation_fee_other",

		"account_advice_partnerfee_guru",
		"account_advice_partnerfee_spets",
		"account_advice_partnerfee_other",
		"account_advice_partnerfee_svan",

		"allocation_type_other",
		"account_advice_partnerfeeinsek_other",

		"checkbox_spets_mjukstart",

		"checkbox_accounttype_guru",
		"checkbox_accounttype_spets",
		"checkbox_accounttype_other",
		"checkbox_accounttype_svan",

		"radio_allocation_perc_guru",
		"radio_allocation_perc_spets",
		"radio_allocation_perc_other",
		"radio_allocation_perc_svan",

		"radio_allocation_risk_guru",
		"radio_allocation_risk_spets",
		"radio_allocation_risk_other",

		"checkbox_advice_customer",
		"advice_customer_suitability",
		"allocation_fxhedge_spets",
		"radio_product_selection_mode",

		"radio_allocation_fee_guru",
		"radio_allocation_fee_spets",
		"radio_allocation_fee_other",

		"allocation_fee_spets_revenueshare",
		"allocation_fee_guru_revenueshare",
		"allocation_fee_other_revenueshare",

		"allocation_fee_guru_rebate",
		"allocation_fee_spets_rebate",
		"allocation_fee_other_rebate",

		"allocation_horizon_other",
		"allocation_horizon_svan",
		"radio_advice_knowledge",

		"customer_account_bank_owner",
		"customer_account_bank",
		"customer_account_bank_custom",
		"customer_account_bank_clearingno",
		"customer_account_bank_no",

		"checkbox_allocation_advice_understand",
		"allocation_perffee_advice",
		"allocation_product_advice",
		"allocation_product_advice_select",

		"allocation_amount_advice",
		"radio_allocation_perc_advice",
		"radio_allocation_risk_advice",
		"checkbox_accounttype_advice",
		"account_advice_partnerfee_advice",
		"allocation_fee_advice",
		"allocation_horizon_advice",

		"customer_guru_depa",
		"customer_spets_depa",
		"customer_other_depa",

		"assets_spets_depa",
		"previous_fee_spets",
		"onetime_fee_spets",
		"future_onetime_fee_spets",

		"assets_guru_depa",
		"previous_fee_guru",
		"onetime_fee_guru",
		"future_onetime_fee_guru",

		"assets_other_depa",
		"previous_fee_other",
		"onetime_fee_other",
		"future_onetime_fee_other",

		"notes_tillagg_other",
		"discagreement_other",

		"allocation_amount_first",
		"allocation_product_first",
		"allocation_product_bilaga_first",
		"radio_allocation_fee_first",
		"allocation_fee_revshare_first",
		"allocation_fee_rebate_first",
		"account_advice_partnerfeeinsek_advice",
		"checkbox_customer_is_audience",

		"radio_spets_fixed_fee",
		"radio_guru_fixed_fee",
		"allocation_amount_text",

		"allocation_amount_second",
		"allocation_product_second",
		"allocation_product_bilaga_second",
		"radio_allocation_fee_second",
		"allocation_fee_revshare_second",
		"allocation_fee_rebate_second"
	];
}