import axios from "axios";
import Bugsnag from '@bugsnag/js';

// CLASSES
import Settings from "@classes/Settings";

export default function apiRequest(path, data, props = {}, returnRaw = false) {
	if (!data) {
		data = {};
	}

  let apiUrl = import.meta.env.VITE_API_URL
  const axiosTimeout = import.meta.env.VITE_AXIOS_TIMEOUT;
	if (apiUrl === "/") { // If we set the api url we will use the same host on port 80 as the api server
		apiUrl = window.location.protocol + "//" + window.location.hostname;
	}

  if (import.meta.env.VITE_NODE_ENV === "development") {
    const apiServerPort = import.meta.env.VITE_API_SERVER_PORT;
    apiUrl = apiServerPort ? apiUrl + ":" + apiServerPort : apiUrl;
  }

	const url = path[0] === "/" ? apiUrl + path : apiUrl + "/" + path;
	const headers = {};
	const authToken = Settings.get("AUTH_TOKEN");

	if (authToken !== null) {
		data.authToken = authToken;
	}

	const config = {
		method: "post",
		data,
		url,
    axiosTimeout,
		...props,
		headers: {...headers, ...props.headers}
	};

	return new Promise((resolve, reject) => {
		axios(config).then(response => {

			if (returnRaw) {
				resolve(response.data);
				return;
			}

			if (typeof response.data === "string") {
        Bugsnag.notify(new Error(response.data));
				reject(new Error(response.data));
				return;
			}

			if (response.data.success === true) {
				resolve(response.data.result);
			}
			else {
        Bugsnag.notify(new Error(response.data.error));
				reject(response.data.error);
			}
		}).catch(error => {
      Bugsnag.notify(error);
			reject(error);
		});
	});
}